import React, { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import Chart from 'react-apexcharts';
import { useSessionManager } from '../components/auth/AuthenticateManager';
import { useAuthenticatedContext } from '../components/auth/AuthenticateContext';

// Chart configurations
const requestsChartOptions = {
    options: {
        chart: {
            id: "basic-line",
            toolbar: { show: false }
        },
        stroke: {
            curve: 'smooth',
            width: 2 
        },
        fill: {
            type: 'gradient',
            gradient: {
                shade: 'light',
                type: 'vertical',
                shadeIntensity: 0.25,
                gradientToColors: undefined,
                inverseColors: true,
                opacityFrom: 0.7,
                opacityTo: 0,
                stops: [0, 90, 100]
            }
        },
        markers: {
            size: 4,
            colors: ['#5a67d8', '#38b2ac'], // Indigo and Teal
            strokeWidth: 2,
            hover: {
                size: 7
            }
        },
        xaxis: {
            categories: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug']
        },
        colors: ['#5a67d8', '#38b2ac'], // Indigo and Teal
        legend: { show: true },
        dataLabels: { enabled: false },
        tooltip: { enabled: true },
        yaxis: { show: false }
    },
    series: [
        { name: 'Max Requests', data: [30, 40, 45, 50, 49, 60, 70, 91] },
        { name: 'Min Requests', data: [10, 20, 25, 30, 29, 40, 50, 61] }
    ]
};

const alertsChartOptions = {
    options: {
        chart: {
            id: 'basic-line',
            toolbar: { show: false }
        },
        stroke: {
            curve: 'smooth',
            width: 2
        },
        markers: {
            size: 4,
            colors: ['#b794f4', '#5a67d8'],
            hover: {
                size: 7
            }
        },
        yaxis: { show: false },
        xaxis: { categories: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug'] },
        colors: ['#b794f4', '#5a67d8'], // Indigo and Light Purple
        legend: { show: true },
        dataLabels: { enabled: false },
    },
    series: [
        { type: 'line',name: 'Security Alerts', data: [10, 15, 12, 8, 7, 6, 10, 12] },
        { 
            type: 'bar', name: 'Active Users', data: [5, 6, 8, 4, 9, 7, 8, 10] }
    ]
};

const pieChartOptions = {
    options: {
        chart: { id: 'basic-pie', toolbar: { show: false } },
        labels: ['Critical', 'Warning', 'Info'],
        colors: ['#A20092', '#FFB44D', '#5a67d8'], // Indigo, Teal, Gray
        legend: { show: true }
    },
    series: [10, 20, 30]
};

const Dashboard = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const { handleJWTSuccess, userInfo } = useSessionManager();
    const { profile } = useAuthenticatedContext();

    useEffect(() => {
        const params = new URLSearchParams(location.search);
        const token = params.get('token');
        if (token) {
            handleJWTSuccess(token);
        }
    }, [location, handleJWTSuccess]);

    const summaryData = [
        { title: "Running Agents", value: "42" },
        { title: "Active Users", value: "15" },
        { title: "Security Alerts", value: "3" },
        { title: "Total Requests", value: "91" }
    ];

    const handleSetupAgent = () => {
        navigate('/agents/new');
    };

    if (profile?.firstAccess) {
        return (
            <div className="container mx-auto p-3 flex">
                <div className="flex-grow p-4">
                    <section className="mb-8">
                        <div className=" px-5 py-5 border border-solid rounded scroll-mt-20">
                            <div className="flex justify-around divide-x divide-gray-200">
                                {summaryData.map((data, index) => (
                                    <div key={index} className="flex flex-col items-center w-1/4 px-4">
                                        <p className="text-sm text-gray-600">{data.title}</p>
                                        <p className="text-3xl font-bold text-gray-600">-</p>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </section>
                    <section className="mb-8">
                        <div className=" px-5 py-5 border border-solid rounded scroll-mt-20">
                            <div className="mx-auto ">
                                <div className="text-center px-80 py-20">
                                    <p className="text-gray-600">You have not setup any agents yet. Click the button below to get started and create your first agent.
                                    </p>
                                    <div className="mt-5 flex items-center justify-center gap-x-6">
                                        <button onClick={handleSetupAgent} className="text-white bg-indigo-500 hover:bg-indigo-700 font-medium rounded text-sm px-5 py-2.5 text-center inline-flex items-center">
                                            Setup Your First Agent
                                        </button>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </section>
                </div>
            </div>
        );
    }

    return (
        <div className="container mx-auto p-3 flex">
            <div className="flex-grow p-4">
                <section className="mb-8">
                    <div className=" px-5 py-5 border border-solid rounded scroll-mt-20">
                        <div className="flex justify-around divide-x divide-gray-200">
                            {summaryData.map((data, index) => (
                                <div key={index} className="flex flex-col items-center w-1/4 px-4">
                                    <p className="text-sm text-gray-600">{data.title}</p>
                                    <p className="text-3xl font-bold text-gray-600">{data.value}</p>
                                </div>
                            ))}
                        </div>
                    </div>
                </section>
                <section className="mb-8">
                    <div className=" px-5 py-5 border border-solid rounded scroll-mt-20">
                        <h2 className="text-lg font-semibold text-gray-600 mb-4">Requests</h2>
                        <Chart options={requestsChartOptions.options} series={requestsChartOptions.series} type="area" height={250} />
                    </div>
                </section>
                <section className="mb-8">
                    <div className="grid grid-cols-1 gap-3 md:grid-cols-2 lg:grid-cols-2">
                        <div className="bg-white rounded border border-gray-200 p-6">
                            <h2 className="text-lg font-semibold text-gray-600 mb-4">Security Alerts and Active Users</h2>
                            <Chart options={alertsChartOptions.options} series={alertsChartOptions.series} type="bar" height={350} />
                        </div>
                        <div className="bg-white rounded border border-gray-200 p-6">
                            <h2 className="text-lg font-semibold text-gray-600 mb-4">Alert Distribution</h2>
                            <Chart options={pieChartOptions.options} series={pieChartOptions.series} type="pie" height={350} />
                        </div>
                    </div>
                </section>
            </div>
        </div>
    );
};

export default Dashboard;
