const RouteDefinitions = [
    { label: 'Dashboard', path: '/dashboard' },
    { 
        label: 'Agents', 
        path: '/agents', 
        children: [
            { label: 'Details', path: '/agents/:agentId/details' },
            { 
                label: 'Tokens', 
                path: '/agents/:agentId/tokens',
                children: [
                    { label: 'New', path: '/agents/:agentId/tokens/new' }
                ]  
            },
            { label: 'Audit', path: '/agents/:agentId/audit' } 
        ] 
    },
    { 
        label: 'Users', 
        path: '/users', 
        children: [
            { label: 'New', path: '/users/new', show: false },
            { label: 'Details', path: '/users/:userId/details' },
            { label: 'Definitions', path: '/users/:userId/definitions' } 
        ] 
    },
    {
        label: 'Settings',
        path: '/settings', 
        children: [
            { label: 'Overview', path: '/settings' },
            { 
                label: 'Definitions', 
                path: '/settings/definitions', 
                children: [
                    { label: 'New', path: '/settings/definitions/new' },
                    { label: 'Details', path: '/settings/definitions/:definitionId/details' },
                    { label: 'Permissions', path: '/settings/definitions/:definitionId/permissions' }
                ] 
            },
            { 
                label: 'Identity Management', 
                path: '/settings/identity-manager', 
                children: [
                    { label: 'New', path: '/settings/identity-manager/new' },
                    { label: 'Details', path: '/settings/identity-manager/:providerId/details' },
                    { label: 'Mapptings', path: '/settings/identity-manager/:providerId/mappings' }
                ] 
            },
            { label: 'Notifications', path: '/settings/notifications' },
            { label: 'Monitoring', path: '/settings/monitoring' },
            { 
                label: 'Billing', 
                path: '/settings/billing',
                children: [
                    { label: 'Subscriptions', path: '/settings/billing/subscriptions/:currentProductId' }
                ] 
            }
        ]
    },
    { label: 'Docs', path: '/docs' },
];

export default RouteDefinitions;
