import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import DataTable from '../../components/common/DataTable';
import { ApiClient } from '../../components/common/ApiClient';
import ActionButton from '../../components/ActionButton';

const AgentsTable = () => {
  const navigate = useNavigate();
  const [agents, setAgents] = useState(null);
  const { get } = ApiClient();

  useEffect(() => {
    get(`/agents`, false).then(response => {
      if (response.success) {
        //setAgents([]);
        setAgents(response.data.data.agents);
      }
    });
  }, []);

  const filterOptions = [
    { key: 'status', value: true, text: 'Active' },
    { key: 'status', value: false, text: 'Suspended' },
    { key: 'status', value: 'all', text: 'All' },
  ];

  const searchableKey = 'name';

  const columns = [
    { key: 'name', label: 'Name' },
    { key: 'runtime', label: 'Runtime' },
    { key: 'version', label: 'Version' },
    { key: 'region', label: 'Region' },
    {
      key: 'status',
      label: 'Status',
      align: 'center',
      render: (item) => item.status ? (
        <span className="inline-flex items-center rounded bg-indigo-50 px-2 py-1 text-xs font-medium text-indigo-700 ring-1 ring-inset ring-indigo-700/10">Active</span>
      ) : (
        <span className="inline-flex items-center rounded bg-amber-50 px-2 py-1 text-xs font-medium text-amber-700 ring-1 ring-inset ring-amber-700/10">Suspended</span>
      ),
    },
    {
      key: 'lastUpdated',
      label: 'Last Updated',
      render: item => new Date(item.lastUpdated).toLocaleString(),
    },
    {
      key: 'options',
      label: 'Options',
      align: 'center',
      render: (item) => (
        <>
          <button onClick={() => navigate(`/agents/${item.id}/details`)} className="text-indigo-600 hover:text-indigo-800 mr-4">
            Details
          </button>
          <button onClick={() => navigate(`/agents/${item.id}/audit`)} className="text-indigo-600 hover:text-indigo-800">
            Audit
          </button>
        </>
      )
    },
  ];

  const addAgent = () => {
    navigate(`/agents/new`);
  };

  const handleSetupAgent = () => {
    navigate(`/agents/new`);
  };

  if (!agents) {
    return <div className="text-center py-5">Loading...</div>;
  }

  return (
    <div className="container mx-auto p-3 flex">
      <div className="flex-grow p-4">
        <section className="mb-8">

          <div className="px-5 py-5 border border-solid rounded scroll-mt-20">
            <div className="flex justify-between items-center">
              <h3 className="text-base font-semibold leading-7 text-gray-600">Platform Agents</h3>
              <ActionButton onClick={addAgent} color="indigo">
                <svg className="w-5 h-5 me-2 -ms-1" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="#ffffff" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"><line x1="12" y1="5" x2="12" y2="19"></line><line x1="5" y1="12" x2="19" y2="12"></line></svg>
                New Agent
              </ActionButton>
            </div>
            <hr className="my-4 border-gray-200" />
            {agents.length === 0 ? (
              <div className="px-5 py-5 scroll-mt-20">
                <div className="mx-auto">
                  <div className="text-center px-80 py-20">
                    <p className="text-sm text-gray-600 mb-4">You have not setup any agents yet. Click the button below to get started and create your first agent.</p>
                    <div className="mt-5 flex items-center justify-center gap-x-6">
                      <ActionButton onClick={handleSetupAgent} color="indigo">
                        Setup Your First Agent
                      </ActionButton>
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              <DataTable
                data={agents}
                columns={columns}
                itemsPerPage={5}
                filterOptions={filterOptions}
                searchableKey={searchableKey}
              />

            )}
          </div>
        </section>
      </div>
    </div>
  );
};

export default AgentsTable;
