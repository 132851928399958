import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Sidebar from '../../../components/common/Sidebar';
import { ApiClient } from '../../../components/common/ApiClient';
import DataTable from '../../../components/common/DataTable';
import ActionButton from '../../../components/ActionButton';

const DefinitionTable = () => {
  const navigate = useNavigate();
  const [definitions, setDefinitions] = useState([]);
  const { get } = ApiClient();

  useEffect(() => {
    get(`/settings/definitions`, false)
      .then(response => {
        if (response.success) {
          setDefinitions(response.data.definitions);
        }
      });
      // eslint-disable-next-line
  }, []);

  const columns = [
    { key: 'name', label: 'Name' },
    { key: 'type', label: 'Type' },
    {
      key: 'lastUpdated',
      label: 'Last Updated',
      render: item => new Date(item.lastUpdated).toLocaleString(),
    },
    {
      key: 'options',
      label: 'Options',
      align: 'center',
      render: (item) => (
        <>
          <button onClick={() => navigate(`/settings/definitions/${item.id}/details`)} className="text-indigo-600 hover:text-indigo-800 mr-4">
            Details
          </button>
          <button onClick={() => navigate(`/settings/definitions/${item.id}/permissions`)} className="text-indigo-600 hover:text-indigo-800">
            Permissions
          </button>
        </>
      )
    },
  ];

  const handleAddNewDefinition = () => {
    navigate('/settings/definitions/new');
  };

  if (!definitions.length) return <div className="text-center py-5">Loading...</div>;

  return (
    <div className="container mx-auto p-3 flex">
      <Sidebar path="Settings" param={[]} />
      <div className="flex-grow p-4">
        <section className="mb-8">
          <div className="px-5 py-5 border border-gray-300 rounded bg-white">
            <div className="flex justify-between items-center">
              <h3 className="text-base font-semibold leading-7 text-gray-600">Definitions of Platform</h3>
              <ActionButton onClick={handleAddNewDefinition} color="indigo">
                <svg className="w-5 h-5 me-2" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="#ffffff" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                  <line x1="12" y1="5" x2="12" y2="19"></line>
                  <line x1="5" y1="12" x2="19" y2="12"></line>
                </svg>
                New Definition
              </ActionButton>
            </div>
            <hr className="my-4 border-gray-200" />
            <p className="text-sm text-gray-600 mb-4">
              Definitions are roles or profiles that assign specific permissions to users, enabling controlled access to cluster resources within Kubernetes. Creating a definition allows administrators to define and enforce policy at a granular level, ensuring users have the appropriate access to perform their tasks. Additionally, definitions can be linked to a configured Identity Provider (IDP), allowing for more sophisticated access controls based on user identity. For more information on IDP mapping, visit <a href="/docs/#idp-mapping" className="text-indigo-600 hover:text-indigo-800">this documentation section</a>.
            </p>

            <DataTable
              data={definitions}
              columns={columns}
              itemsPerPage={5}
            />
          </div>
        </section>
      </div>
    </div>
  );
};

export default DefinitionTable;
