import { useNavigate } from 'react-router-dom';
import { useAuthenticatedContext } from './AuthenticateContext';
import { ApiClient } from '../common/ApiClient';
import {jwtDecode} from 'jwt-decode';

export const useSessionManager = () => {
    const { setProfile, profile } = useAuthenticatedContext();
    const { post } = ApiClient();
    const navigate = useNavigate();

    const handleJWTSuccess = (jwtToken) => {
        const userData = jwtDecode(jwtToken);
        setProfile({
            id: userData.id, 
            name: userData.name,
            email: userData.email,
            picture: userData.picture,
            firstAccess: userData.firstAccess,
            token: jwtToken
        });
        localStorage.setItem('userProfile', JSON.stringify(userData));
        navigate('/dashboard');
    };

    const k8smanAuthUrl = 'http://localhost:8066';

    const login = async (provider, email = '', password = '') => {
        try {
            let response;
            if (provider === 'native') {
                response = await post('/auth/native/login', { email, password }, false, k8smanAuthUrl);
                if (response.success) {
                    window.location.href = response.data.redirect_url;
                } else {
                    throw new Error(`${provider} login failed`);
                }
            } else {
                window.location.href = `${k8smanAuthUrl}/auth/${provider}/login`;
            }
        } catch (error) {
            console.error(`${provider} login error:`, error);
        }
    };

    return { login, handleJWTSuccess, profile };
};
