import React, { useState, useEffect } from 'react';
import ActionButton from '../../../components/ActionButton';

const ProviderSamlv2CertificatePopup = ({ isOpen, onClose, onUpdate, currentCertificate, isUpdate }) => {
    const [certificate, setCertificate] = useState('');
    const [isBase64, setIsBase64] = useState(true);

    useEffect(() => {
        if (currentCertificate) {
            setCertificate(currentCertificate);
            validateBase64(currentCertificate);
        }
    }, [currentCertificate]);

    const validateBase64 = (value) => {
        try {
            atob(value);
            setIsBase64(true);
        } catch (error) {
            setIsBase64(false);
        }
    };

    const handleCertificateChange = (e) => {
        const value = e.target.value;
        setCertificate(value);
        validateBase64(value);
    };

    const toggleCertificateView = () => {
        if (isBase64) {
            try {
                setCertificate(atob(certificate));
                setIsBase64(false);
            } catch (error) {
                alert('Failed to decode the certificate. Ensure it is valid Base64.');
            }
        } else {
            try {
                setCertificate(btoa(certificate));
                setIsBase64(true);
            } catch (error) {
                alert('Failed to encode the certificate. Ensure it is valid text.');
            }
        }
    };

    const handleSubmit = () => {
        onUpdate(certificate);
    };

    if (!isOpen) return null;

    return (
        <div className="fixed inset-0 z-10 overflow-y-auto">
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"></div>
            <div className="flex min-h-full items-center justify-center p-4 text-center sm:items-center sm:p-0">
                <div className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-4xl">
                    <div className="border-b border-gray-200">
                        <div className="bg-white pt-6 sm:p-6 sm:pb-1">
                            <div className="flex justify-between items-center mb-3">
                                <h3 className="text-lg leading-6 font-medium text-gray-600" id="modal-title">SAML Certificate</h3>
                                <button className="text-gray-500 hover:text-gray-600" onClick={onClose}>
                                    <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
                                    </svg>
                                </button>
                            </div>
                        </div>
                    </div>
                    <div className="text-left p-6">
                        <p className="text-sm text-gray-500">
                            {isUpdate ? "Modify your SAML certificate in either Base64 or plain text format. Note: Changes will only apply to the certificate settings and not affect other configurations of your identity provider. It’s important to update accurately to maintain uninterrupted and secure SAML authentication. Verify details carefully before saving." :
                                "Upload and configure your SAML certificate in either Base64 or plain text format. Setting up the correct certificate is crucial as it authenticates your identity provider in SAML v2 processes. Remember, this initial configuration establishes the foundation for secure communications. Please ensure the certificate is accurate to avoid authentication issues."
                            }
                        </p>
                        <textarea
                            className="w-full mt-2 border border-gray-300 rounded shadow-sm p-2 text-sm text-gray-600 focus:outline-none focus:ring focus:border-indigo-300"
                            value={certificate}
                            onChange={handleCertificateChange}
                            rows="10"
                            placeholder="Enter certificate"
                        />
                        <div className="mt-4">
                            <ActionButton onClick={toggleCertificateView} color="indigo">
                                {isBase64 ? 'Decode Certificate' : 'Encode Certificate'}
                            </ActionButton>
                        </div>
                    </div>
                    <div className="bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
                        <ActionButton onClick={handleSubmit} color="indigo">Update Certificate</ActionButton>
                        <ActionButton onClick={onClose} color="indigo" styleType="transparent">Cancel</ActionButton>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ProviderSamlv2CertificatePopup;
