import React, { createContext, useContext, useState } from 'react';

const ToastContext = createContext();

export const useToast = () => useContext(ToastContext);

const ToastNotification2 = ({ children }) => {
  const [toast, setToast] = useState({ message: '', type: '', open: false });

  const addToast = (message, type) => {
    setToast({ message, type, open: true });
    const duration = type === 'error' ? 8000 : 5000;
    setTimeout(() => {
      setToast({ message: '', type: '', open: false });
    }, duration);
  };

  const [show, setShow] = useState(true);
  return (
    <ToastContext.Provider value={{ addToast }}>
      {children}
      {toast.open && (
        
        <div className={`flex items-center w-full max-w-xs p-4 mb-4 text-gray-500 bg-white rounded shadow fixed top-10 left-1/2 transform -translate-x-1/2 z-50 ${toast.type === 'error' ? 'text-red-500 bg-red-100' : 'text-green-500 bg-green-100'}`} role="alert">
          <div className="inline-flex items-center justify-center flex-shrink-0 w-8 h-8">
            <svg className="w-5 h-5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
              {toast.type === 'error'
                ? <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 11.793a1 1 0 1 1-1.414 1.414L10 11.414l-2.293 2.293a1 1 0 0 1-1.414-1.414L8.586 10 6.293 7.707a1 1 0 0 1 1.414-1.414L10 8.586l2.293-2.293a1 1 0 0 1 1.414 1.414L11.414 10l2.293 2.293Z"/>
                : <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z"/>
              }
            </svg>
          </div>
          <div className="ms-3 text-sm font-normal">{toast.message}</div>
          <button type="button" className={`ms-auto -mx-1.5 -my-1.5 bg-white text-gray-400 hover:text-gray-600 rounded p-1.5 inline-flex items-center justify-center h-8 w-8 ${toast.type === 'error' ? 'text-red-500 bg-red-100' : 'text-green-500 bg-green-100'}`} onClick={() => setToast({ message: '', type: '', open: false })} aria-label="Close">
            <svg className="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
              <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"/>
            </svg>
          </button>
        </div>
        
      )}
    </ToastContext.Provider>
  );
};

export default ToastNotification2;
