import React from 'react';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import CheckoutForm from './CheckoutForm';

const stripePromise = loadStripe('pk_test_51OjkceI0rUjks4W1S75A8h7bQEGpNfhcEMNj8rg1D890PHWWzWBl8CJmNPxTk4dPc9PGuhMYohLbWUPluTjFjQfd00EetkxZtw');

const ModalPaymentMethod = ({ isOpen, onClose, customerStripeId, profile, onPaymentMethodAdded }) => {
    if (!isOpen) return null;

    return (
        <div className="fixed inset-0 z-10 overflow-y-auto">
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"></div>
            <div className="flex min-h-full items-center justify-center p-4 text-center sm:items-center sm:p-0">
                <div className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-2xl">
                    <div className="border-b border-gray-200">
                        <div className="bg-white pt-6 sm:p-6 sm:pb-1">
                            <div className="flex justify-between items-center mb-3">
                                <h3 className="text-lg leading-6 font-medium text-gray-600" id="modal-title">
                                    Add Payment Method
                                </h3>
                                <button className="text-gray-500 hover:text-gray-600" onClick={onClose}>
                                    <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
                                    </svg>
                                </button>
                            </div>
                        </div>
                    </div>
                    <div className="text-left p-6">
                        <p className="text-sm text-gray-500 pb-4">
                            Please enter your payment details below to add your payment method. This is essential for ensuring uninterrupted service.
                        </p>
                        <Elements stripe={stripePromise} options={{ locale: 'US' }}>
                            <CheckoutForm onClose={onClose} customerStripeId={customerStripeId} profile={profile} onPaymentMethodAdded={onPaymentMethodAdded} />
                        </Elements>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ModalPaymentMethod;
