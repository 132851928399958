import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import SignIn from './pages/SignIn';
import { AuthenticatedProvider } from './components/auth/AuthenticateContext';
import AuthenticatedRoutes from './components/auth/AuthenticateRoutes';
import ToastNotification2 from './components/common/ToastNotification2';  // Import without braces

function App() {
  return (
    <AuthenticatedProvider>
      <ToastNotification2>
        <Router>
          <div className="App">
            <div className="app-container">
              <Routes>
                <Route path="/" element={<SignIn />} />
                <Route path="/signin" element={<SignIn />} />
                <Route path="/forgot-password" element={<SignIn />} />
                <Route path="/reset-password" element={<SignIn />} />
                <Route path="/*" element={<AuthenticatedRoutes />} />
              </Routes>
            </div>
          </div>
        </Router>
      </ToastNotification2>
    </AuthenticatedProvider>
  );
}

export default App;
