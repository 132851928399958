import React, { useState, useEffect } from 'react';
import { useParams, useNavigate, Link } from 'react-router-dom';
import Sidebar from '../../components/common/Sidebar';
import CopyButton from '../../components/CopyButton';
import DeletePopup from '../../components/common/DeletePopup';
import { ApiClient } from '../../components/common/ApiClient';
import ActionButton from '../../components/ActionButton';

const AgentDetails = () => {
  const [agent, setAgent] = useState(null);
  const [showPopup, setShowPopup] = useState(false);
  const [secrets, setSecrets] = useState({ SecretKey: 'Loading...' });
  const [canCopySecret, setCanCopySecret] = useState(false);
  const navigate = useNavigate();
  const { agentId } = useParams();
  const { get, put, del } = ApiClient();

  useEffect(() => {
    get(`/agents/${agentId}`, false).then(response => {
      if (response.success) {
        setAgent(response.data);
        const secretData = response.data.secrets.find(s => s.name === 'secretKey');
        if (secretData) {
          setSecrets({ SecretKey: secretData.value });
        }
      }
    });
  }, [agentId]);

  const handleSuspendAgent = () => {
    const newStatus = agent.status ? false : true;
    put(`/agents/${agentId}`, { status: newStatus })
      .then(response => {
        if (response.success) {
          setAgent(prev => ({ ...prev, status: newStatus }));
        }
      });
  };

  const handleDeleteAgent = () => {
    del(`/agents/${agentId}`)
      .then(response => {
        if (response.success) {
          navigate('/agents');
        }
      });
  };

  const generateRandomString = length => {
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    let result = '';
    for (let i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * characters.length));
    }
    setCanCopySecret(true);
    return result;
  };

  const generateSecrets = () => {
    setSecrets({ SecretKey: generateRandomString(42) });
  };

  const formatDate = dateString => {
    const date = new Date(dateString);
    return date.toLocaleDateString() + ' ' + date.toLocaleTimeString();
  };

  if (!agent) return <div className="text-center py-5">Loading...</div>;

  return (
    <div className="container mx-auto p-3 flex">
      <Sidebar path="Agents" param={{ agentId }} />
      <div className="flex-grow p-4">
        <section className="mb-8">
          <div className="px-5 py-5 border border-solid rounded">
            <h3 className="text-base font-semibold leading-7 text-gray-600">Agent Status</h3>
            <hr className="my-4 border-gray-200" />
            <div className="grid grid-cols-1 md:grid-cols-3 gap-20">
              <div className="mb-2 text-sm">
                Name: <span className="font-bold">{agent.name}</span>
              </div>
              <div className="mb-2 text-sm">
                Runtime: <span className="font-bold">{agent.runtime}</span>
                <div className="mb-2 text-sm">
                  Version: <span className="font-bold">{agent.version}</span>
                </div>
              </div>
              <div className="mb-2 text-sm">
                Status: <span className="font-bold">{agent.status ? 'Active' : 'Suspended'}</span>
                <div className="mb-2 text-sm">
                  Updated: <span className="font-bold">{formatDate(agent.lastUpdated)}</span>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="mb-8">
          <div className="px-5 py-5 border border-solid rounded">
            <h3 className="text-base font-semibold leading-7 text-gray-600">Installation Secret Management</h3>
            <hr className="my-4 border-gray-200" />

            <p className="text-sm text-gray-600 mb-4">
              Manage installation secrets critical for the operation of the agent within the Kubernetes cluster. Do not share these secrets outside the intended use cases. For temporary access needs to operate commands in the Kubernetes cluster, consider using <Link to={`/agents/${agentId}/tokens`} className="text-indigo-600 hover:text-indigo-800">Breaking Glass Tokens</Link> on the token management page.
            </p>
            <hr className="my-4 border-gray-200" />
            <div className="mb-4">
              <p className="text-sm text-gray-600 mb-4">Installation Secret Key:</p>
              <div className="relative flex items-center mt-1 bg-gray-50 p-3 rounded">
                <code className="text-sm text-gray-600 overflow-auto flex-grow">{secrets.SecretKey}</code>
                {canCopySecret && <CopyButton textToCopy={secrets.SecretKey} />}
              </div>
              <p className="mt-4 mb-4 text-sm text-gray-500">
                This secret is generated exclusively for the k8sman agent and is used during communication setups with the Kubernetes API; it is an installation token for the agent in the target Kubernetes cluster. If regenerated, you must replace the token of your agent installed in the Kubernetes cluster.
              </p>
              <div className="flex justify-end">
                <ActionButton onClick={generateSecrets} color="indigo">
                  Regenerate Secret
                </ActionButton>
              </div>
            </div>
          </div>
        </section>

        <section className="mb-8">
          <div className="px-5 py-5 border border-solid rounded">
            <h3 className="text-base font-semibold leading-7 text-gray-600">Suspend Agent</h3>
            <hr className="my-4 border-gray-200" />
            <p className="text-sm text-gray-600 mb-4">
              Suspending this K8sman agent will prevent users from performing any operations using this agent. Please proceed with caution as this action may affect all users relying on the agent's functionalities.
            </p>
            <div className="flex justify-end">
              <ActionButton onClick={handleSuspendAgent} color={agent.status ? 'red' : 'green'}>
                {agent.status ? 'Suspend' : 'Activate'} Agent
              </ActionButton>
            </div>
          </div>
        </section>

        <section className="mb-8">
          <div className="px-5 py-5 border border-solid border-red-500 rounded">
            <h3 className="text-lg font-semibold leading-7 text-red-500">Danger Zone</h3>
            <hr className="my-4 border-red-300" />
            <p className="text-sm text-red-600 mb-4">
              This action is irreversible. Deleting this agent will permanently remove all its settings and data from the system. Please proceed with extreme caution.
            </p>
            <div className="flex justify-end">
              <ActionButton onClick={() => setShowPopup(true)} color="red">
                Delete Agent
              </ActionButton>
            </div>
          </div>
          <DeletePopup
            isOpen={showPopup}
            onClose={() => setShowPopup(false)}
            onConfirm={handleDeleteAgent}
            typeText={`delete ${agent?.name}`}
          />
        </section>
      </div>
    </div>
  );
};

export default AgentDetails;
