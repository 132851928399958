import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { ApiClient } from '../../components/common/ApiClient';
import Sidebar from '../../components/common/Sidebar';
import UserPermissionConfig from './UserPermissionConfig';

const UserDefinition = () => {
  const { userId } = useParams();
  const navigate = useNavigate();
  const [userDetails, setUserDetails] = useState(null);
  const [selectedDefinitions, setSelectedDefinitions] = useState([]);
  const apiClient = ApiClient();

  useEffect(() => {
    fetchUserDetails();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userId]);

  const fetchUserDetails = async () => {
    const response = await apiClient.get(`/users/${userId}`, false);
    if (response.success) {
      setUserDetails(response.data);
      setSelectedDefinitions(response.data.definitions || []);
    }
  };

  const updateSelectedDefinitions = (newDefinitions) => {
    setSelectedDefinitions(newDefinitions);
  };

  const handleUpdateDefinition = async () => {
    const payload = {
      id: userDetails.id,
      name: userDetails.name,
      email: userDetails.email,
      providerId: userDetails.providerId,
      definitions: selectedDefinitions.map(def => ({
        id: def.id,
        name: def.name
      }))
    };
    const response = await apiClient.post(`/users/${userId}/definitions`, payload);
    if (response.success) {
      navigate(`/users`);
    }
  };

  if (!userDetails) {
    return <div className="text-center py-5">Loading...</div>;
  }

  return (
    <div className="container mx-auto p-3 flex">
      <Sidebar path="Users" param={{ userId }} />
      <div className="flex-grow p-4">
        <section className="mb-8">
          <div className="px-5 py-5 border border-solid rounded">
            <div className="flex justify-between items-center">
              <h3 className="text-base font-semibold leading-7 text-gray-600">User Definitions</h3>
              <button
                type="button"
                className={`text-white font-medium rounded text-sm px-5 py-2.5 text-center inline-flex items-center mb-2 ${selectedDefinitions.length > 0 ? "bg-indigo-500 hover:bg-indigo-700" : "bg-gray-500 cursor-not-allowed"}`}
                onClick={handleUpdateDefinition}
                disabled={selectedDefinitions.length === 0}
              >
                Update Definitions
              </button>
            </div>
            <hr className="my-4 border-gray-200" />
            <UserPermissionConfig
              mode="update"
              userDetail={userDetails}
              updateSelectedDefinitions={updateSelectedDefinitions}
            />
          </div>
        </section>
      </div>
    </div>
  );
};

export default UserDefinition;
