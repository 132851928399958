import React, { useEffect, useState, useMemo } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import RouteDefinitions from './RouteDefinitions';

const generatePath = (template, params = {}) => {
  return Object.entries(params).reduce((currentPath, [key, value]) => currentPath.replace(`:${key}`, value), template);
};

const isActive = (item, currentPath, params = {}) => {
  const fullPath = generatePath(item.path, params);
  const isExactMatch = fullPath === currentPath;
  const isParentMatch = item.children && currentPath.startsWith(fullPath + '/'); 

  return isExactMatch || isParentMatch;
};


const Sidebar = ({ path, param }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [activeMenuSection, setActiveMenuSection] = useState(null);

  useEffect(() => {
    const section = RouteDefinitions.find((section) => section.label === path);
    setActiveMenuSection(section);
  }, [path]);

  const menuItems = useMemo(() => (
    activeMenuSection?.children.filter(item => item.show !== false).map((item) => ({
      ...item,
      isActive: isActive(item, location.pathname, param),
      hasChildren: !!item.children
    })) || []
  ), [activeMenuSection, location.pathname, param]);

  const handleNavigate = (templatePath) => {
    navigate(generatePath(templatePath, param));
  };

  return (
    <aside aria-label="Sidebar" className="w-64">
      <div className="antialiased overflow-y-auto py-4 px-3">
        <ul className="space-y-2">
          {menuItems.map((item) => (
            <li
              key={item.path}
              className={`rounded p-2 w-60 ${isActive(item, location.pathname, param) ? 'bg-indigo-100' : 'hover:bg-indigo-50'} cursor-pointer`}
              onClick={() => handleNavigate(item.path)}
            >
              {item.label}
            </li>
          ))}
        </ul>
      </div>
    </aside>
  );
};

export default Sidebar;
