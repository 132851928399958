import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import Sidebar from '../../../components/common/Sidebar';
import ResourceTable from './permission/ResourceTable';
import { ApiClient } from '../../../components/common/ApiClient';
import ActionButton from '../../../components/ActionButton';
import { useNavigate } from 'react-router-dom';

const DefinitionPermissions = () => {
  const { definitionId } = useParams();
  const navigate = useNavigate();
  const [searchTerm, setSearchTerm] = useState('');
  const [definitionPermissions, setDefinitionPermissions] = useState({
    id: '',
    name: '',
    type: '',
    lastUpdated: '',
    permissions: {},
  });

  const { get, put } = ApiClient();

  useEffect(() => {
    get(`/settings/definitions/${definitionId}`, false).then(response => {
      if (response.success) {
        const { id, name, type, lastUpdated, permissions } = response.data;
        setDefinitionPermissions({ id, name, type, lastUpdated, permissions: permissions || {} });
      }
    });
    // eslint-disable-next-line
  }, [definitionId]);

  const handlePermissionToggle = (updatedPermissions) => {
    setDefinitionPermissions(prev => ({ ...prev, permissions: updatedPermissions }));
  };

  const handleUpdatePermissions = () => {
    const payload = { id: definitionPermissions.id, permissions: definitionPermissions.permissions };
    put(`/settings/definitions/${definitionId}`, payload).then(response => {
      if (response.success) {
        navigate('/settings/definitions');
      }
    });
  };

  return (
    <div className="container mx-auto p-3 flex">
      <Sidebar path="Settings" param={[]} />
      <div className="flex-grow p-4">
        <section className="mb-8">
          <div className="px-5 py-5 border border-gray-300 rounded bg-white">
            <div className="flex justify-between items-center">
              <h3 className="text-base font-semibold leading-7 text-gray-600">Permissions</h3>
              <ActionButton onClick={handleUpdatePermissions} color="indigo">Update Permissions</ActionButton>
            </div>
            <hr className="my-4 border-gray-200" />
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
              <div>
                <div className="mb-2">Name: <span className="font-bold">{definitionPermissions.name}</span></div>
                <div className="mb-2">Type: <span className="font-bold">{definitionPermissions.type}</span></div>
              </div>
              <div>
                <div className="mb-2">Updated: <span className="font-bold">{definitionPermissions.lastUpdated}</span></div>
                <div className="mb-2">ID: <span className="font-bold">{definitionPermissions.id}</span></div>
              </div>
            </div>
            <hr className="my-4 border-gray-200" />
            <ResourceTable
              searchTerm={searchTerm}
              setSearchTerm={setSearchTerm}
              permissions={definitionPermissions.permissions}
              onTogglePermission={handlePermissionToggle}
            />
          </div>
        </section>
      </div>
    </div>
  );
};

export default DefinitionPermissions;
