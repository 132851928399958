import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import Sidebar from '../../../components/common/Sidebar';
import ActionButton from '../../../components/ActionButton';
import { ApiClient } from '../../../components/common/ApiClient'; // Importing the ApiClient
import LoadingOverlay from '../../../components/spinner/LoadingOverlay'; // Assuming you have a LoadingOverlay component

const Subscriptions = () => {
  const navigate = useNavigate(); // Corrected useNavigate hook usage
  const { currentProductId } = useParams();
  const [products, setProducts] = useState([]);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [isLoading, setIsLoading] = useState(true); // Loading state
  const { get, post } = ApiClient();

  const k8smanBillingUrl = "http://localhost:8080";

  useEffect(() => {
    if (currentProductId) {
      get(`/billing/products/${currentProductId}`, false, k8smanBillingUrl).then(responseProducts => {
        if (responseProducts.success) {
          setProducts(responseProducts.data.products);
          if (responseProducts.data.currentProduct) {
            setSelectedProduct(responseProducts.data.currentProduct.id);
          }
        }
        setIsLoading(false); // Stop loading
      }).catch(() => {
        setIsLoading(false); // Stop loading in case of error
      });
    }
  }, [currentProductId]);

  const handleProductChange = (productId) => {
    setSelectedProduct(productId);
  };

  const handleUpdateSubscription = () => {
    post('/billing/subscription/update', { customerId: currentProductId, newProductId: selectedProduct }, k8smanBillingUrl)
      .then(responseSubscription => {
        if (responseSubscription.success) {
          navigate(`/settings/billing/subscriptions/${currentProductId}`);
        }
      });
  };

  return (
    <div className="container mx-auto p-3 flex">
      <Sidebar path="Settings" />
      <div className="flex-grow p-4">
        <section className="mb-8">
          <div className="px-5 py-5 border border-gray-300 rounded bg-white">
            <div className="flex justify-between items-center mb-4">
              <h3 className="text-xl font-semibold leading-7 text-gray-600">Change Product:</h3>
              <ActionButton onClick={handleUpdateSubscription} color="indigo">Update Subscription</ActionButton>
            </div>
            <hr className="my-4 border-gray-200" />
            <p className="text-gray-600 mb-4">
              You can change your product at any time. Your subscription will be updated according to the selected product's billing cycle. Please note that some features may stop working based on the characteristics of the selected product.
            </p>
            {isLoading ? (
              <LoadingOverlay isLoading={isLoading} /> // Display loading overlay while loading
            ) : (
              <div className="flex flex-wrap -mx-2">
                {products.map((product, index) => (
                  <div key={index} className="p-2 xl:w-1/3 md:w-1/2 w-full">
                    <div className={`h-full p-6 rounded border flex flex-col relative overflow-hidden ${selectedProduct === product.id ? 'border-indigo-500' : 'border-gray-300'}`}>
                      {selectedProduct === product.id && <span className="bg-indigo-500 text-white px-3 py-1 tracking-widest text-xs absolute right-0 top-0 rounded-bl">SELECTED</span>}
                      <div className="flex items-center mb-4">
                        <input
                          type="radio"
                          name="product"
                          checked={selectedProduct === product.id}
                          onChange={() => handleProductChange(product.id)}
                          className="form-radio h-5 w-5 text-indigo-600"
                        />
                        <label className="ml-2 text-lg font-semibold text-gray-800">{product.name}</label>
                      </div>
                      <h2 className="text-sm tracking-widest title-font mb-1 font-medium">{product.name.toUpperCase()}</h2>
                      <h1 className="text-5xl text-gray-900 pb-4 mb-4 border-b border-gray-200 leading-none">{product.price}</h1>
                      <p className="flex items-center text-gray-600 mb-2">
                        <span className="w-4 h-4 mr-2 inline-flex items-center justify-center bg-gray-400 text-white rounded-full flex-shrink-0">
                          <svg fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2.5" className="w-3 h-3" viewBox="0 0 24 24">
                            <path d="M20 6L9 17l-5-5"></path>
                          </svg>
                        </span>{product.users}
                      </p>
                      <p className="flex items-center text-gray-600 mb-2">
                        <span className="w-4 h-4 mr-2 inline-flex items-center justify-center bg-gray-400 text-white rounded-full flex-shrink-0">
                          <svg fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2.5" className="w-3 h-3" viewBox="0 0 24 24">
                            <path d="M20 6L9 17l-5-5"></path>
                          </svg>
                        </span>{product.bandwidth}
                      </p>
                      {product.features.map((feature, i) => (
                        <p key={i} className="flex items-center text-gray-600 mb-2">
                          <span className="w-4 h-4 mr-2 inline-flex items-center justify-center bg-gray-400 text-white rounded-full flex-shrink-0">
                            <svg fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2.5" className="w-3 h-3" viewBox="0 0 24 24">
                              <path d="M20 6L9 17l-5-5"></path>
                            </svg>
                          </span>{feature}
                        </p>
                      ))}
                      {
                        product.unavailableFeatures.length > 0 && (
                          product.unavailableFeatures.map((feature, i) => (
                            <p key={i} className="flex items-center text-gray-400 mb-2 line-through">
                              <span className="w-4 h-4 mr-2 inline-flex items-center justify-center bg-gray-400 text-white rounded-full flex-shrink-0">
                                <svg fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2.5" className="w-3 h-3" viewBox="0 0 24 24">
                                  <path d="M20 6L9 17l-5-5"></path>
                                </svg>
                              </span>{feature}
                            </p>
                          )))
                      }
                      <button className={`flex items-center mt-auto text-white border-0 py-2 px-4 w-full focus:outline-none rounded ${selectedProduct === product.id ? 'bg-indigo-500 hover:bg-indigo-600' : 'bg-gray-400 hover:bg-gray-500'}`}>
                        {selectedProduct === product.id ? 'Selected' : 'Select Product'}
                        <svg fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" className="w-4 h-4 ml-auto" viewBox="0 0 24 24">
                          <path d="M5 12h14M12 5l7 7-7 7"></path>
                        </svg>
                      </button>
                      <p className="text-xs text-gray-500 mt-3">Some additional information about this product.</p>
                    </div>
                  </div>
                ))}
              </div>
            )}
          </div>
        </section>
      </div>
    </div>
  );
};

export default Subscriptions;
