import React, { useState } from 'react';
import ActionButton from '../ActionButton';

const DeletePopup = ({ isOpen, onClose, onConfirm, typeText }) => {
    const [inputValue, setInputValue] = useState('');
    const confirmText = `${typeText}`;

    if (!isOpen) return null;

    return (
        <div className="relative z-10" aria-labelledby="modal-title" role="dialog" aria-modal="true">
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"></div>
            <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
                <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                    <div className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">
                        <div className="border-b border-gray-200">
                            <div className="bg-white pt-6 sm:p-6 sm:pb-1">
                                <div className="flex justify-between items-center mb-3">
                                    <h3 className="text-base font-semibold leading-6 text-gray-700" id="modal-title">Confirm Action</h3>
                                    <button className="text-gray-500 hover:text-gray-600" onClick={onClose}>
                                        <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
                                        </svg>
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div className="text-left p-6">
                            <p className="text-sm text-gray-700">Please type "{typeText}" to confirm.</p>
                            <input
                                type="text"
                                placeholder="Type here..."
                                className="text-black font-medium py-2 px-4 rounded border border-gray-300 w-full my-4"
                                value={inputValue}
                                onChange={e => setInputValue(e.target.value)}
                            />
                        </div>
                        <div className="bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
                            <button
                                type="button"
                                className="text-white bg-red-500 hover:bg-red-700 font-medium rounded text-sm px-5 py-2.5 text-center inline-flex items-center disabled:opacity-50"
                                onClick={() => {
                                    if (inputValue === confirmText) {
                                        onConfirm();
                                        onClose(); // Close the modal on confirmation
                                    } else {
                                        alert('Please type the correct confirmation text.');
                                    }
                                }}
                                disabled={inputValue !== confirmText}
                            >
                                Confirm
                            </button>
                            <ActionButton onClick={onClose} type="button" color="red" styleType="transparent">
                                Cancel
                            </ActionButton>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default DeletePopup;
