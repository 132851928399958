import React, { useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { ApiClient } from '../../components/common/ApiClient';
import CopyButton from '../../components/CopyButton';
import ActionButton from '../../components/ActionButton';

const AgentTokensCreateForm = () => {
  const [tokenDetails, setTokenDetails] = useState({
    name: '',
    description: '',
    startTime: '',
    duration: 1 // Default duration in hours
  });
  const { agentId } = useParams();
  const [step, setStep] = useState(1);
  const navigate = useNavigate();
  const { post } = ApiClient();

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setTokenDetails(prev => ({ ...prev, [name]: value }));
  };

  const handleNext = async () => {
    if (step === 1) {
      const response = await post(`/agents/${agentId}/tokens/generate`, tokenDetails);
      if (response.success) {
        setTokenDetails(response.data.data);
      }
      setStep(2);
    }
  };

  const handleCancel = () => {
    navigate(`/agents/${agentId}/tokens`);
  };

  return (
    <section className="text-gray-600 body-font">
      <div className="container px-5 py-10 mx-auto flex flex-wrap">
        <div className="flex flex-wrap w-full">
          <div className="lg:w-2/5 md:w-1/2 md:pr-10 md:py-6">
            <div className="flex relative pb-12">
              <div className="h-full w-10 absolute inset-0 flex items-center justify-center">
                <div className="h-full w-1 bg-gray-200 pointer-events-none"></div>
              </div>
              <div className="flex-shrink-0 w-10 h-10 rounded-full bg-indigo-500 inline-flex items-center justify-center text-white relative z-10">
                <svg fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" className="w-5 h-5" viewBox="0 0 24 24">
                  <path d="M12 22s8-4 8-10V5l-8-3-8 3v7c0 6 8 10 8 10z"></path>
                </svg>
              </div>
              <div className="flex-grow pl-4">
                <h2 className="font-medium title-font text-sm text-gray-600 mb-1 tracking-wider">STEP 1: Define Token Details</h2>
                <p className="text-sm">Enter the token name, duration, and a brief description. Make sure the name and description clearly reflect the token's intended use.</p>
              </div>
            </div>
            <div className="flex relative">
              <div className="flex-shrink-0 w-10 h-10 rounded-full bg-indigo-500 inline-flex items-center justify-center text-white relative z-10">
                <svg fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" className="w-5 h-5" viewBox="0 0 24 24">
                  <path d="M22 11.08V12a10 10 0 11-5.93-9.14"></path>
                  <path d="M22 4L12 14.01l-3-3"></path>
                </svg>
              </div>
              <div className="flex-grow pl-4">
                <h2 className="font-medium title-font text-sm text-gray-600 mb-1 tracking-wider">STEP 2: Token Information</h2>
                <p className="text-sm">The information of token and details about expiration of token with sample of command usage.</p>
              </div>
            </div>
          </div>
          <div className="lg:w-3/5 md:w-1/2 rounded md:mt-0 mt-12">
            <div className="px-5 py-5 border border-solid rounded">
              <h3 className="text-base font-semibold leading-7 text-gray-600">Create New Breaking Glass Token</h3>
              <hr className="my-4 border-gray-200" />
              {step === 1 && (
                <>
                  <p className="text-sm text-gray-500 mb-4">
                    Configure the details for the new breaking glass token, including the start time and duration. Ensure all information accurately reflects the intended usage and validity period.
                  </p>

                  <div className="mb-4">
                    <label htmlFor="description" className="block mb-2 text-sm font-medium text-gray-600">Description</label>
                    <textarea
                      id="description"
                      name="description"
                      className="mt-1 block w-full flex-grow p-2 border rounded mb-2 text-sm text-gray-600 focus:outline-none focus:ring focus:border-indigo-300"
                      value={tokenDetails.description}
                      onChange={handleInputChange}
                      required
                    />
                  </div>
                  <div className="mb-4">
                    <label htmlFor="startTime" className="block mb-2 text-sm font-medium text-gray-600">Start Time</label>
                    <input
                      type="datetime-local"
                      id="startTime"
                      name="startTime"
                      className="w-full flex-grow p-2 border rounded mb-2 text-sm text-gray-600 focus:outline-none focus:ring focus:border-indigo-300"
                      onChange={handleInputChange}
                      required
                    />
                  </div>
                  <div className="mb-4">
                    <label htmlFor="duration" className="block mb-2 text-sm font-medium text-gray-600">Duration (hours)</label>
                    <input
                      type="number"
                      id="duration"
                      name="duration"
                      className="w-full flex-grow p-2 border rounded mb-2 text-sm text-gray-600 focus:outline-none focus:ring focus:border-indigo-300"
                      onChange={handleInputChange}
                      required
                    />
                  </div>
                  <div className="flex justify-end">
                    <ActionButton onClick={handleCancel} color="indigo" styleType="transparent">
                      Cancel
                    </ActionButton>
                    <ActionButton onClick={handleNext} color="indigo">
                      Create Token
                    </ActionButton>
                  </div>
                </>
              )}
              {step === 2 && (
                <div>
                  <p className="mt-2 max-w-2xl text-sm text-gray-500">
                    The breaking glass token has been successfully generated and is ready for use. Please note: This token will not be displayed again and should be copied now.
                  </p>
                  <div className="mt-5">
                    <div className="flex flex-col space-y-4">
                      <div>
                        <p className="font-medium text-gray-600">Token Details: {tokenDetails.description}</p>
                        <p className="font-medium text-gray-600">Start Time: {tokenDetails.startTime} - Duration (hours): {tokenDetails.duration}</p>
                        <p className="font-medium text-gray-600">Expiration Time: {tokenDetails.startTime}</p>
                      </div>
                      <div>
                        <p className="font-medium text-gray-600">Token:</p>
                        <div className="relative flex items-center mt-1 bg-gray-50 p-3 rounded">
                          <code className="text-sm text-gray-600 overflow-auto flex-grow">{tokenDetails.token}</code>
                          <CopyButton textToCopy={tokenDetails.token} />
                        </div>
                      </div>
                      <div>
                        <p className="font-medium text-gray-600">Use in your CLI:</p>
                        <div className="relative flex items-center mt-1 bg-gray-50 p-3 rounded">
                          <code className="text-sm text-gray-600 overflow-auto flex-grow">k8sman connection create CONN_NAME --secret={tokenDetails.token}</code>
                          <CopyButton textToCopy={`k8sman connection create CONN_NAME --secret=${tokenDetails.token}`} />
                        </div>
                      </div>
                    </div>
                    <div className="flex justify-end mt-4">
                      <ActionButton onClick={handleCancel} color="indigo">
                        Close
                      </ActionButton>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default AgentTokensCreateForm;
