import React from 'react';

const Pagination = ({ pages, currentPage, setPage }) => {
  const maxVisiblePages = 3;

  const handlePageClick = (pageNumber) => {
    setPage(pageNumber);
  };

  const handlePreviousClick = () => {
    if (currentPage > 1) {
      setPage(currentPage - 1);
    }
  };

  const handleNextClick = () => {
    if (currentPage < pages) {
      setPage(currentPage + 1);
    }
  };

  const renderPageNumbers = () => {
    const startPage = Math.max(1, currentPage - maxVisiblePages);
    const endPage = Math.min(pages, currentPage + maxVisiblePages);
    const pageRange = [];

    for (let i = startPage; i <= endPage; i++) {
      pageRange.push(i);
    }

    return pageRange.map(number => (
      <button
        key={number}
        onClick={() => handlePageClick(number)}
        className={`relative inline-flex items-center px-4 py-2 text-sm font-semibold ${currentPage === number ? 'bg-indigo-500 text-white' : 'text-gray-600 ring-1 ring-inset ring-gray-300 hover:bg-gray-50'} focus:z-20 focus:outline-offset-0`}
      >
        {number}
      </button>
    ));
  };

  return (
    <div className="flex items-center justify-between border-t border-gray-300 bg-white  py-3 ">
      <div className="flex flex-1 justify-between sm:hidden">
        <button 
          onClick={handlePreviousClick}
          className="relative inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-600 hover:bg-gray-50"
          disabled={currentPage === 1}
        >
          Previous
        </button>
        <button 
          onClick={handleNextClick}
          className="relative ml-3 inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-600 hover:bg-gray-50"
          disabled={currentPage === pages}
        >
          Next
        </button>
      </div>
      <div className="hidden sm:flex sm:flex-1 sm:items-center sm:justify-between">
        <div>
          <p className="text-sm text-gray-600">
            Showing <span className="font-medium">{currentPage}</span> of <span className="font-medium">{pages}</span> pages
          </p>
        </div>
        <div>
          <nav className="isolate inline-flex -space-x-px rounded" aria-label="Pagination">
            <button 
              onClick={handlePreviousClick}
              className="relative inline-flex items-center rounded-l px-2 py-2 text-gray-400 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0"
              disabled={currentPage === 1}
            >
              <span className="sr-only">Previous</span>
              <svg className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                <path fillRule="evenodd" d="M12.79 5.23a.75.75 0 01-.02 1.06L8.832 10l3.938 3.71a.75.75 0 11-1.04 1.08l-4.5-4.25a.75.75 0 010-1.08l4.5-4.25a.75.75 0 011.06.02z" clipRule="evenodd" />
              </svg>
            </button>
            {renderPageNumbers()}
            <button 
              onClick={handleNextClick}
              className="relative inline-flex items-center rounded-r px-2 py-2 text-gray-400 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0"
              disabled={currentPage === pages}
            >
              <span className="sr-only">Next</span>
              <svg className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                <path fillRule="evenodd" d="M7.21 14.77a.75.75 0 01.02-1.06L11.168 10 7.23 6.29a.75.75 0 111.04-1.08l4.5 4.25a.75.75 0 010 1.08l-4.5 4.25a.75.75 0 01-1.06-.02z" clipRule="evenodd" />
              </svg>
            </button>
          </nav>
        </div>
      </div>
    </div>
  );
};

export default Pagination;
