import React, { useState, useEffect } from 'react';
import Spinner from './Spinner';

const LoadingOverlay = ({ isLoading, children, timeout = 5000 }) => {
  const [showSpinner, setShowSpinner] = useState(false);
  const [isTimeoutOver, setIsTimeoutOver] = useState(false);

  useEffect(() => {
    let timer;
    if (isLoading) {
      setShowSpinner(true);
      setIsTimeoutOver(false);
      timer = setTimeout(() => {
        setShowSpinner(false);
        setIsTimeoutOver(true);
      }, timeout);
    } else {
      setShowSpinner(false);
      setIsTimeoutOver(true);
    }
    return () => clearTimeout(timer);
  }, [isLoading, timeout]);

  return (
    <div className="relative">
      {showSpinner && (
        <div className="absolute inset-0 flex items-center justify-center bg-white bg-opacity-50">
          <Spinner size={8} />
        </div>
      )}
      <div className={`${showSpinner ? 'opacity-20' : 'opacity-100'}`}>
        {children}
      </div>
    </div>
  );
};

export default LoadingOverlay;
