import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import Select from 'react-select';
import Sidebar from '../../../components/common/Sidebar';
import { ApiClient } from '../../../components/common/ApiClient';
import DataTable from '../../../components/common/DataTable';
import ActionButton from '../../../components/ActionButton';

const ProviderSamlvsMappings = () => {
  const { providerId } = useParams();
  const navigate = useNavigate();
  const [definitions, setDefinitions] = useState([]);
  const [groupName, setGroupName] = useState('');
  const [attributeKey, setAttributeKey] = useState('');
  const [attributeValue] = useState('');
  const [linkedGroups, setLinkedGroups] = useState([]);
  const [selectedDefinition, setSelectedDefinition] = useState(null);
  const { get, post, del } = ApiClient();

  useEffect(() => {
    get('/settings/definitions', false).then(response => {
      const options = response.data.definitions.map(def => ({ label: def.name, value: def.id }));
      setDefinitions(options);
    });

    get(`/settings/identity-manager/saml/${providerId}/mappings`, false).then(response => {
      setLinkedGroups(response.data.mappings);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [providerId]);

  const handleLink = () => {
    if (!groupName || !selectedDefinition || !attributeKey || !attributeValue) {
      alert("Please ensure all fields are selected and filled out.");
      return;
    }
    post(`/settings/identity-manager/saml/${providerId}/mappings`, {
      groupName,
      definitionId: selectedDefinition.value,
      attributeKey,
      attributeValue
    }).then(() => {
      navigate('/settings/saml');
    });
  };

  const handleDeleteLink = (linkId) => {
    del(`/settings/identity-manager/saml/mappings/${linkId}`).then(() => {
      setLinkedGroups(prev => prev.filter(link => link.id !== linkId));
    });
  };

  const columns = [
    { key: 'definitionName', label: 'Definition' },
    { key: 'attributeKey', label: 'Attribute Key' },
    { key: 'attributeValue', label: 'Attribute Value' },
    { key: 'dateCreated', label: 'Date Created', render: item => new Date(item.dateCreated).toLocaleString() },
    {
      key: 'options',
      label: 'Options',
      align: 'center',
      render: (item) => (
        <button onClick={() => handleDeleteLink(item.id)} className="text-indigo-600 hover:text-indigo-800">
          Delete
        </button>
      )
    },
  ];

  return (
    <div className="container mx-auto p-3 flex">
      <Sidebar path="Settings" param={[]} />
      <div className="flex-grow p-4">
        <div className="px-5 py-5 border border-solid rounded">
          <h3 className="text-base font-semibold leading-7 text-gray-600">SAML Group Mappings</h3>
          <hr className="my-4 border-gray-200" />
          <p className="mb-4 text-sm text-gray-600">
            Configure SAML group mappings to control access based on roles and permissions. Ensure accurate group names to match those in your SAML provider.
          </p>
          <div className="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
            <div className="sm:col-span-6">
              <label htmlFor="definition" className="block mb-2 text-sm font-medium text-gray-600">Definition</label>
              <Select
                options={definitions}
                value={selectedDefinition}
                onChange={setSelectedDefinition}
                placeholder="Select a definition"
                styles={{
                  control: (baseStyles, state) => ({
                    ...baseStyles,
                    borderColor: '#d1d5db',
                    boxShadow: state.isFocused ? '0 0 0 3px #a5b4fc' : 'none',
                    '&:hover': {
                      borderColor: state.isFocused ? '#d1d5db' : '#9ca3af',
                    },
                  }),
                  option: (baseStyles, state) => ({
                    ...baseStyles,
                    backgroundColor: state.isSelected ? '#4f46e5' : state.isFocused ? '#e0e7ff' : '#ffffff',

                  }),
                  singleValue: (baseStyles) => ({
                    ...baseStyles,
                    color: '#6b7280',
                  }),
                  placeholder: (baseStyles) => ({
                    ...baseStyles,
                    color: '#6b7280', // text-gray-600
                  }),
                  input: (baseStyles) => ({
                    ...baseStyles,
                    color: '#4b5563', // text-gray-700
                  }),
                }}
              />
            </div>
            <div className="sm:col-span-3">
              <label htmlFor="groupName" className="block mb-2 text-sm font-medium text-gray-600">Group Name</label>
              <input
                id="groupName"
                type="text"
                value={groupName}
                onChange={e => setGroupName(e.target.value)}
                className="mt-1 block w-full flex-grow p-2 border rounded mb-2 text-sm text-gray-600 focus:outline-none focus:ring focus:border-indigo-300"
                placeholder="Enter Group Name"
              />
            </div>
            <div className="sm:col-span-3">
              <label htmlFor="attributeKey" className="block mb-2 text-sm font-medium text-gray-600">Attribute Key</label>
              <input
                id="attributeKey"
                type="text"
                value={attributeKey}
                onChange={e => setAttributeKey(e.target.value)}
                className="mt-1 block w-full flex-grow p-2 border rounded mb-2 text-sm text-gray-600 focus:outline-none focus:ring focus:border-indigo-300"
                placeholder="Enter Attribute Key"
              />
            </div>
          </div>
          <div className="flex justify-end mt-4">
            <ActionButton onClick={handleLink} color="indigo">
              Link Definition
            </ActionButton>
          </div>
          <DataTable
            data={linkedGroups}
            columns={columns}
            itemsPerPage={5}
          />
        </div>
      </div>
    </div>
  );
};

export default ProviderSamlvsMappings;
