import React, {useState} from 'react';

const CopyButton = ({ textToCopy }) => {
    const [copied, setCopied] = useState(false);

    const copyToClipboard = (text, setCopied) => {
        navigator.clipboard.writeText(text).then(() => {
            setCopied(true);
            setTimeout(() => setCopied(false), 2000); // Reset copied state after 2 seconds
        }).catch((err) => {
            console.error('Failed to copy text: ', err);
        });
    };

    return (
        <button onClick={() => copyToClipboard(textToCopy, setCopied)} className="text-gray-500 hover:text-gray-600 ml-4">
            {copied ? (
                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="#4a90e2" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"><polyline points="20 6 9 17 4 12"></polyline>
                </svg>
            ) : (
                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="#9b9b9b" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                    <path d="M16 4h2a2 2 0 0 1 2 2v14a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2V6a2 2 0 0 1 2-2h2"></path><rect x="8" y="2" width="8" height="4" rx="1" ry="1"></rect>
                </svg>
            )}
        </button>
    );
};

export default CopyButton;
