import React, { useState } from 'react';
import { useSessionManager } from '../components/auth/AuthenticateManager';

const SignIn = () => {
    const [email, setEmail] = useState('user@example.com');
    const [password, setPassword] = useState('user');
    const { login } = useSessionManager();

    const handleNativeLogin = (e) => {
        e.preventDefault();
        login('native', email, password);
    };

    return (
        <section className="text-gray-600 body-font">
            <div className="max-w-lg mx-auto bg-white p-20">
                <div className="flex justify-center">
                    <img src='/K.png' alt='k8sman' className='h-12 mb-4' />
                </div>
                <h1 className="text-3xl font-medium text-center">Welcome Back!</h1>
                <p className="text-slate-500 text-center mb-5">Please sign in to your account</p>

                <div className="space-y-4">
                    <button onClick={() => login('google')} className="w-full text-center py-3 my-3 border flex space-x-2 items-center justify-center border-slate-200 rounded text-slate-700 hover:border-slate-400 hover:text-slate-900 hover:shadow transition duration-150">
                        <img src="https://www.svgrepo.com/show/355037/google.svg" className="w-6 h-6" alt="Google" />
                        <span>Login with Google</span>
                    </button>
                    <button onClick={() => login('github')} className="w-full text-center py-3 my-3 border flex space-x-2 items-center justify-center border-slate-200 rounded text-slate-700 hover:border-slate-400 hover:text-slate-900 hover:shadow transition duration-150">
                        <img src="https://www.svgrepo.com/show/361181/github.svg" className="w-6 h-6" alt="GitHub" />
                        <span>Login with GitHub</span>
                    </button>
                </div>

                <form onSubmit={handleNativeLogin} className="my-10">
                    <div className="flex flex-col space-y-5">
                        <label htmlFor="email">
                            <p className="font-medium text-slate-700 pb-2">Email address</p>
                            <input value={email} onChange={e => setEmail(e.target.value)} id="email" name="email" type="email" className="w-full py-3 border border-slate-200 rounded px-3 focus:outline-none focus:border-slate-500 hover:shadow" placeholder="Enter email address" />
                        </label>
                        <label htmlFor="password">
                            <p className="font-medium text-slate-700 pb-2">Password</p>
                            <input value={password} onChange={e => setPassword(e.target.value)} id="password" name="password" type="password" className="w-full py-3 border border-slate-200 rounded px-3 focus:outline-none focus:border-slate-500 hover:shadow" placeholder="Enter your password" />
                        </label>
                        <div className="flex items-center justify-between">
                            <label htmlFor="remember" className="flex items-center space-x-2">
                                <input type="checkbox" id="remember" className="form-checkbox w-4 h-4 text-indigo-600 border-slate-200 rounded focus:ring-indigo-500" />
                                <span>Remember Me</span>
                            </label>
                            <button className="font-medium text-indigo-600">Forgot Password?</button>
                        </div>
                        <button type="submit" className="w-full py-3 font-medium text-white bg-indigo-600 hover:bg-indigo-500 rounded border-indigo-500 hover:shadow inline-flex space-x-2 items-center justify-center">
                            <span>Login</span>
                        </button>
                        <p className="text-center">Not registered yet? <button className="text-indigo-600 font-medium inline-flex space-x-1 items-center"><span>Register now </span><span><svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="2">
                            <path strokeLinecap="round" strokeLinejoin="round" d="M10 6H6a2 2 0 00-2 2v10a2 2 0 002 2h10a2 2 0 002-2v-4M14 4h6m0 0v6m0-6L10 14" />
                        </svg></span></button></p>
                    </div>
                </form>
            </div>
        </section>
    );
};

export default SignIn;