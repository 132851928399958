import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import Sidebar from '../../../components/common/Sidebar';
import DeletePopup from '../../../components/common/DeletePopup';
import { ApiClient } from '../../../components/common/ApiClient';
import Select from 'react-select';
import ActionButton from '../../../components/ActionButton';

const DefinitionDetail = () => {
  const typeOptions = [
    { label: 'Kubernetes', value: 'Kubernetes' },
    { label: 'OpenShift', value: 'OpenShift' },
    { label: 'Custom', value: 'Custom' }
  ];
  const [definition, setDefinition] = useState({ name: '', type: '-', lastUpdated: '' });
  const [showPopup, setShowPopup] = useState(false);
  const { definitionId } = useParams();
  const navigate = useNavigate();
  const { get, put, del } = ApiClient();

  useEffect(() => {
    get(`/settings/definitions/${definitionId}/details`, false)
      .then(response => {
        if (response.success) {
          setDefinition(response.data);
        }
      });
    // eslint-disable-next-line
  }, [definitionId]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setDefinition(prev => ({ ...prev, [name]: value }));
  };

  const handleSelectChange = (selectedOption) => {
    setDefinition(prev => ({ ...prev, type: selectedOption.value }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const { success } = await put(`/settings/definitions/${definitionId}`, definition);
    if (success) {
      navigate(`/settings/definitions`);
    }
  };

  const handleDelete = async () => {
    const { success } = await del(`/settings/definitions/${definitionId}`);
    if (success) {
      navigate('/settings/definitions');
    }
  };

  return (
    <div className="container mx-auto p-3 flex">
      <Sidebar path="Settings" param={{ 'definitionId': definitionId }} />
      <div className="flex-grow p-4">
        <section className="mb-8">
          <div className="px-5 py-5 border border-gray-300 rounded bg-white">
            <h3 className="text-base font-semibold leading-7 text-gray-600">Definition Details</h3>
            <hr className="my-4 border-gray-200" />
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
              <div>
                <div className="mb-2">ID: <span className="font-bold">{definition.id}</span></div>
              </div>
              <div>
                <div className="mb-2">Last Updated: <span className="font-bold">{definition.lastUpdated}</span></div>
              </div>
            </div>
          </div>
        </section>
        <section className="mb-8">
          <div className="px-5 py-5 border border-gray-300 rounded bg-white">
            <h3 className="text-base font-semibold leading-7 text-gray-600">Edit Definition</h3>
            <hr className="my-4 border-gray-200" />
            <form onSubmit={handleSubmit}>
              <div className="mb-4">
                <label htmlFor="name" className="block mb-2 text-sm font-medium text-gray-600">Name</label>
                <input
                  id="name"
                  name="name"
                  type="text"
                  value={definition.name}
                  onChange={handleInputChange}
                  className="mt-1 block w-full flex-grow p-2 border rounded mb-2 text-sm text-gray-600 focus:outline-none focus:ring focus:border-indigo-300"
                  required
                />
              </div>
              <div className="mb-4">
                <label htmlFor="type" className="block mb-2 text-sm font-medium text-gray-600">Type</label>
                <Select
                  options={typeOptions}
                  value={definition.type}
                  onChange={handleSelectChange}
                  placeholder="Select a type"
                  styles={{
                    control: (baseStyles, state) => ({
                      ...baseStyles,
                      borderColor: '#d1d5db',
                      boxShadow: state.isFocused ? '0 0 0 3px #a5b4fc' : 'none',
                      '&:hover': {
                        borderColor: state.isFocused ? '#d1d5db' : '#9ca3af',
                      },
                    }),
                    option: (baseStyles, state) => ({
                      ...baseStyles,
                      backgroundColor: state.isSelected ? '#4f46e5' : state.isFocused ? '#e0e7ff' : '#ffffff',

                    }),
                    singleValue: (baseStyles) => ({
                      ...baseStyles,
                      color: '#6b7280',
                    }),
                    placeholder: (baseStyles) => ({
                      ...baseStyles,
                      color: '#6b7280', // text-gray-600
                    }),
                    input: (baseStyles) => ({
                      ...baseStyles,
                      color: '#4b5563', // text-gray-700
                    }),
                  }}
                />
              </div>
              <div className="flex justify-end">
                <ActionButton type="submit" color="indigo">Update Definition</ActionButton>
              </div>
            </form>
          </div>
        </section>
        <section className="mb-8">
          <div className="px-5 py-5 border border-red-500 rounded bg-white">
            <h3 className="text-lg font-semibold leading-7 text-red-500">Danger Zone</h3>
            <hr className="my-4 border-red-300" />
            <p className="text-red-500 mb-4">
              Deleting this definition {definition.name} will permanently remove it and prevent definitions from logging in using this provider. Please proceed with caution as this action is irreversible.
            </p>
            <div className="flex justify-end mt-4">
              <ActionButton onClick={() => setShowPopup(true)} color="red">Delete Definition</ActionButton>
            </div>
          </div>
          <DeletePopup
            isOpen={showPopup}
            onClose={() => setShowPopup(false)}
            onConfirm={handleDelete}
            typeText={`delete ${definition?.name}`}
          />
        </section>
      </div>
    </div>
  );
};

export default DefinitionDetail;
