import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import Sidebar from '../../../components/common/Sidebar';
import DeletePopup from '../../../components/common/DeletePopup';
import { ApiClient } from '../../../components/common/ApiClient';
import ProviderSamlv2CertificatePopup from './ProviderSamlv2CertificatePopup';
import ActionButton from '../../../components/ActionButton';

const ProviderSamlv2Detail = () => {
  const [providerDetails, setProviderDetails] = useState({
    name: '',
    idpEndpoint: '',
    nameIdFormat: '',
    issuerUrl: '',
    lastUpdated: '',
    certificateEncoded: '',
  });
  const { providerId } = useParams();
  const navigate = useNavigate();
  const { get, put, del } = ApiClient();

  const [showPopup, setShowPopup] = useState(false);
  const [showCertificatePopup, setShowCertificatePopup] = useState(false);

  useEffect(() => {
    get(`/settings/identity-manager/providers/saml/${providerId}`, false)
      .then(response => {
        if (response.success) {
          setProviderDetails(response.data.data);
        }
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [providerId]);

  const handleDelete = async () => {
    const { success } = await del(`/settings/identity-manager/providers/saml/${providerId}`);
    if (success) {
      navigate('/settings/identity-manager/providers');
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setProviderDetails(prev => ({ ...prev, [name]: value }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const { success } = await put(`/settings/identity-manager/providers/saml/${providerId}`, providerDetails);
    if (success) {
      navigate(`/settings/identity-manager`);
    }
  };

  const handleUpdateCertificate = async (newCertificate) => {
    // Assume newCertificate is already a Base64 encoded string
    const updatedDetails = { ...providerDetails, certificateEncoded: newCertificate };
    const { success } = await put(`/settings/identity-manager/providers/saml/${providerId}`, updatedDetails);
    if (success) {
      setProviderDetails(updatedDetails);
      setShowCertificatePopup(false);
    }
  };

  return (
    <div className="container mx-auto p-3 flex">
      <Sidebar path="Settings" param={{ 'providerId': providerId }} />
      <div className="flex-grow p-4">
        <section className="mb-8">
          <div className="px-5 py-5 border border-solid rounded scroll-mt-20">
            <h3 className="text-base font-semibold leading-7 text-gray-600">Provider Details</h3>
            <hr className="my-4 border-gray-200" />
            <div>
              <div className="mb-2">SAML v2 Callback URL (ACS): <span className="font-bold">{providerDetails.callbackUrl}</span></div>
              <div className="mb-2">SAML v2 Issuer: <span className="font-bold">{providerDetails.issuerUrl}</span></div>
              <div className="mb-2">SAML v2 Metadata URL: <span className="font-bold">{providerDetails.metadataUrl}</span></div>
              <div className="mb-2">Last Updated: <span className="font-bold">{providerDetails.lastUpdated}</span></div>
            </div>
          </div>
        </section>
        <section className="mb-8">
          <div className="px-5 py-5 border border-solid rounded scroll-mt-20">
            <h3 className="text-base font-semibold leading-7 text-gray-600">Edit Provider</h3>
            <hr className="my-4 border-gray-200" />
            <form onSubmit={handleSubmit}>
              <div className="mb-4">
                <label htmlFor="name" className="block mb-2 text-sm font-medium text-gray-600">Name</label>
                <input
                  id="name"
                  name="name"
                  type="text"
                  value={providerDetails.name}
                  onChange={handleInputChange}
                  className="mt-1 block w-full flex-grow p-2 border rounded mb-2 text-sm text-gray-600 focus:outline-none focus:ring focus:border-indigo-300"
                  required
                />
              </div>
              <div className="mb-4">
                <label htmlFor="idpEndpoint" className="block mb-2 text-sm font-medium text-gray-600">IdP Endpoint</label>
                <input
                  id="idpEndpoint"
                  name="idpEndpoint"
                  type="url"
                  value={providerDetails.idpEndpoint}
                  onChange={handleInputChange}
                  className="mt-1 block w-full flex-grow p-2 border rounded mb-2 text-sm text-gray-600 focus:outline-none focus:ring focus:border-indigo-300"
                  required
                />
              </div>
              <div className="mb-4">
                <label htmlFor="nameIdFormat" className="block mb-2 text-sm font-medium text-gray-600">NameID Format</label>
                <input
                  id="nameIdFormat"
                  name="nameIdFormat"
                  type="text"
                  value={providerDetails.nameIdFormat}
                  onChange={handleInputChange}
                  className="mt-1 block w-full flex-grow p-2 border rounded mb-2 text-sm text-gray-600 focus:outline-none focus:ring focus:border-indigo-300"
                  required
                />
              </div>
              <div className="mb-4">
                <label className="block mb-2 text-sm font-medium text-gray-600">Certificate of Digital Signature</label>
                <div className="p-6 border border-indigo-200 rounded shadow-sm bg-indigo-50">
                  <p className="text-sm text-gray-600">
                    A digital signature certificate authenticates the identity provider in SAML v2 processes. If your certificate is due for renewal or if you are experiencing any issues,
                    <button type="button" onClick={() => setShowCertificatePopup(true)} className="ml-2 inline text-indigo-600 hover:text-indigo-800 transition duration-150 ease-in-out font-medium underline">
                      click here
                    </button> to update it.
                  </p>
                </div>
              </div>
              <div className="flex justify-end">
                <ActionButton type="submit" color="indigo">
                  Update Provider
                </ActionButton>
              </div>
            </form>
          </div>
        </section>
        <section className="mb-8">
          <div className="px-5 py-5 border border-solid border-red-500 rounded scroll-mt-20">
            <h3 className="text-lg font-semibold leading-7 text-red-500">Danger Zone</h3>
            <hr className="my-4 border-red-300" />
            <p className="text-red-600 mb-4">
              Deleting this provider will permanently remove it and may disrupt services depending on this identity provider. Please proceed with caution as this action is irreversible.
            </p>
            <div className="flex justify-end mt-4">
              <ActionButton onClick={() => setShowPopup(true)} color="red">
                Delete Provider
              </ActionButton>
            </div>
          </div>
          <DeletePopup
            isOpen={showPopup}
            onClose={() => setShowPopup(false)}
            onConfirm={handleDelete}
            typeText={`delete ${providerDetails.name}`}
          />
          <ProviderSamlv2CertificatePopup
            isOpen={showCertificatePopup}
            onClose={() => setShowCertificatePopup(false)}
            onUpdate={handleUpdateCertificate}
            currentCertificate={providerDetails.certificateEncoded}
            isUpdate={true}
          />
        </section>
      </div>
    </div>
  );
};

export default ProviderSamlv2Detail;
