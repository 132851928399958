import axios from 'axios';
import { useToast } from './ToastNotification2'; 

const defaultClient = axios.create({
  baseURL: 'http://localhost:6001/v1/api',
  headers: {
    'Content-Type': 'application/json',
    'Authorization': 'xxxxxxxxxx' 
  }
});

export const ApiClient = () => {
  const { addToast } = useToast();

  const requestHandler = (response, showToast = true) => {
    if (showToast) {
      addToast(response.data.message || 'Operation successful!', 'success');
    }
    return { success: true, data: response.data };
  };

  const errorHandler = (error, showToast = true) => {
    let errorMessage = error.response?.data?.message || 'An error occurred!';
    if (showToast || (error.response && error.response.status >= 400)) {
      addToast(errorMessage, 'error');
    }
    console.error('API Error:', error);
    return { success: false, message: errorMessage };
  };
  
  const getClient = (host) => {
    return host ? axios.create({
      baseURL: host,
      headers: {
        'Content-Type': 'application/json',
        'Authorization': defaultClient.defaults.headers['Authorization']
      }
    }) : defaultClient;
  }

  const get = async (url, showToast = true, host = '') => {
    const client = getClient(host);
    return client.get(url).then(response => requestHandler(response, showToast)).catch(error => errorHandler(error, showToast));
  };

  const post = async (url, data, showToast = true, host = '') => {
    const client = getClient(host);
    return client.post(url, data).then(response => requestHandler(response, showToast)).catch(error => errorHandler(error, showToast));
  };

  const put = async (url, data, showToast = true, host = '') => {
    const client = getClient(host);
    return client.put(url, data).then(response => requestHandler(response, showToast)).catch(error => errorHandler(error, showToast));
  };

  const del = async (url, showToast = true, host = '') => {
    const client = getClient(host);
    return client.delete(url).then(response => requestHandler(response, showToast)).catch(error => errorHandler(error, showToast));
  };

  return { get, post, put, del };
};
