import React, { useState } from 'react';
import { useStripe, useElements, CardElement, AddressElement } from '@stripe/react-stripe-js';
import ActionButton from '../../../components/ActionButton';
import { ApiClient } from '../../../components/common/ApiClient';

const CheckoutForm = ({ onClose, customerStripeId, profile, onPaymentMethodAdded }) => {
    const stripe = useStripe();
    const elements = useElements();
    const { post } = ApiClient();
    const [errorMessage, setErrorMessage] = useState('');
    const [addressData, setAddressData] = useState(null);
    const [isLoading, setIsLoading] = useState(false);

    const handleSubmit = async (event) => {
        event.preventDefault();
        setIsLoading(true);

        if (!stripe || !elements || !addressData) {
            setErrorMessage('Stripe.js has not yet loaded or address data is missing.');
            setIsLoading(false);
            return;
        }

        const cardElement = elements.getElement(CardElement);

        const { error, paymentMethod } = await stripe.createPaymentMethod({
            type: 'card',
            card: cardElement,
            billing_details: {
                name: addressData.name,
                address: addressData.address,
            },
        });

        if (error) {
            setErrorMessage(error.message);
            setIsLoading(false);
        } else {
            const k8smanBillingUrl = 'http://localhost:8080';
            const payload = {
                paymentMethodId: paymentMethod.id,
                customerId: profile.id,
                customerEmail: profile.email,
                customerStripeId: customerStripeId,
                customerName: addressData.name,
            };

            const response = await post('/billing/customer/payment-method', payload, false, k8smanBillingUrl);
            if (response.success) {
                console.log('[PaymentMethod]', response.data);
                onPaymentMethodAdded();
                onClose();
            } else {
                setErrorMessage(response.error);
                console.error('[PaymentMethod Error]', response.error);
                setIsLoading(false);
            }
        }
    };

    return (
        <form onSubmit={handleSubmit} className="space-y-4">
            {errorMessage && <p className="text-red-600">{errorMessage}</p>}
            <AddressElement
                options={{ mode: 'billing' }}
                onChange={(event) => {
                    if (event.complete) {
                        setAddressData(event.value);
                    }
                }}
            />
            <div>
                <label htmlFor="card-element" className="block text-sm font-medium text-gray-700 pb-1">Card Information</label>
                <CardElement
                    id="card-element"
                    options={{ disableLink: true, hidePostalCode: true }}
                    className="block w-full flex-grow p-3 border rounded mb-2 text-sm text-gray-600 focus:outline-none focus:ring focus:border-indigo-300"
                />
            </div>
            <div className="flex justify-end">
                <ActionButton onClick={onClose} type="button" color="indigo" styleType="transparent">
                    Close
                </ActionButton>
                <ActionButton type="submit" disabled={!stripe} color="indigo" isLoading={isLoading}>
                    Add Payment Method
                </ActionButton>
            </div>
        </form>
    );
};

export default CheckoutForm;
