import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import Sidebar from '../../components/common/Sidebar';
import { ApiClient } from '../../components/common/ApiClient';
import CopyButton from '../../components/CopyButton';
import ActionButton from '../../components/ActionButton';

const AgentAudit = () => {
  const [logs, setLogs] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');
  const { agentId } = useParams();
  const { get } = ApiClient();

  // Fetch all logs on component mount
  useEffect(() => {
    fetchLogs();
  }, [agentId]);

  const fetchLogs = (query) => {
    get(`/agents/${agentId}/audit${query ? `?search=${query}` : ''}`, false).then(response => {
      if (response.success) {
        setLogs(response.data.logs);
      }
    });
  };

  const handleSearch = () => {
    fetchLogs(searchTerm);
  };

  const getLogDetails = (log) => {
    return `${log.timestamp} ${log.level}: ${log.message} ${JSON.stringify(log.details)}`;
  };

  const truncateText = (text, maxLength = 100) => {
    return text.length > maxLength ? `${text.substring(0, maxLength)}...` : text;
  };

  if (!logs) return <div className="text-center py-5">Loading...</div>;

  return (
    <div className="container mx-auto p-3 flex">
      <Sidebar path="Agents" param={{ 'agentId': agentId }} />
      <div className="flex-grow p-4">
        <section className="mb-8">
          <div className="px-5 py-5 border border-solid rounded scroll-mt-20">
            <h3 className="text-base font-semibold leading-7 text-gray-600">Agent Audit Trail</h3>
            <hr className="my-4 border-gray-200" />
            <p className="text-sm text-gray-600 mb-4">
              The audit trail below provides a chronological log of activities and events related to this agent. It includes timestamps, levels, and descriptions for each entry, giving you insight into the operational history of the agent. Currently, this view shows all recorded logs. In a future update, you'll have the ability to filter this log to quickly find specific events or issues.
            </p>
            <p className="text-sm text-gray-600 mb-4">
              Utilize this information to monitor the agent's performance, diagnose problems, or verify actions. Each log entry is an immutable record, ensuring the integrity and reliability of the audit.
            </p>

            <div className="flex mb-4">
              <input
                type="text"
                placeholder="Search logs..."
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                className="flex-grow p-2 border rounded mr-4 text-sm text-gray-600 focus:outline-none focus:ring focus:border-indigo-300"
              />
              <ActionButton onClick={handleSearch} color="indigo">
                Search
              </ActionButton>
            </div>
            <hr className="my-4 border-gray-200" />
            <ul className='list-group list-group-lg'>
              {logs.map((log, index) => (
                <li key={index} className="r-event p-4 list-none overflow-hidden border border-solid first:rounded-tl first:rounded-tr last:rounded-bl last:rounded-br border-b-0 last:border-b">
                  <div className="flex justify-between items-start">
                    <div className="flex-1 min-w-0">
                      <div className="text-gray-600 mb-3">
                        <span className="inline-block">
                          {truncateText(`${log.timestamp} ${log.level}: ${log.message}`)}
                        </span>
                      </div>
                      <div className="font-normal text-xs text-indigo-500 leading-4 mt-2 mb-1">
                        {truncateText(JSON.stringify(log.details))}
                      </div>
                    </div>
                    <CopyButton textToCopy={getLogDetails(log)} />
                  </div>
                </li>
              ))}
            </ul>
          </div>
        </section>
      </div>
    </div>
  );
};

export default AgentAudit;
