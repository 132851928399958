import React from 'react';
import Sidebar from '../../components/common/Sidebar';
import { Link } from 'react-router-dom';

const SettingsOverview = () => {

    return (
        <div className="container mx-auto p-3 flex">
            <Sidebar path="Settings" param={[]} />
            <div className="flex-grow p-4">
                <section className="mb-8">
                    {/* Billing and Plan Information */}
                    {/* Billing and Plan Information */}
                    <div className="bg-white dark:bg-gray-900 border-solid rounded mb-4">
                        <div className="py-8 px-4 mx-auto max-w-screen-xl text-center lg:py-16">
                            <h2 className="mb-4 text-2xl font-bold text-gray-600 md:text-3xl dark:text-white">Your Plan & Billing</h2>
                            <p className="mb-4 text-lg text-gray-500 lg:text-lg dark:text-gray-400">
                                You are currently on the <strong>Free Version</strong>. Upgrade to unlock more features.
                            </p>
                            <Link to="/upgrade" className="py-2 px-4 text-sm font-medium text-white bg-indigo-600 rounded hover:bg-indigo-700">
                                Upgrade Plan
                            </Link>
                            <Link to="/billing" className="ml-4 py-2 px-4 text-sm font-medium text-gray-600 bg-white rounded border border-gray-300 hover:bg-gray-100 dark:text-white dark:bg-gray-800 dark:border-gray-600 dark:hover:bg-gray-700">
                                Manage Billing
                            </Link>
                        </div>
                    </div>
                    <div className="px-5 py-5 border border-solid rounded shadow-sm">
                        <h3 className="text-base font-semibold leading-7 text-gray-600">Settings Overview</h3>
                        <hr className="my-4 border-gray-200" />
                        <p className="text-sm text-gray-500 mb-4">
                            Overview of your current plan details, system configurations, and other settings.
                        </p>
                        <div className="mt-6">

                            {/* System Configuration Details */}
                            <div class="px-4 sm:px-0">
                                <dl className="divide-y divide-gray-100">
                                    <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                                        <dt className="text-sm font-medium leading-6 text-gray-600">Currently plan</dt>
                                        <dd className="mt-1 text-sm leading-6 text-gray-600 sm:col-span-2 sm:mt-0">
                                            <span className="inline-flex items-center rounded bg-indigo-50 px-2 py-1 text-xs font-medium text-indigo-700 ring-1 ring-inset ring-indigo-700/10">Free</span>
                                            <Link to="/docs" className="text-blue-600 hover:text-blue-800 dark:hover:text-blue-500 ml-5">Change plan</Link>
                                            </dd>
                                    </div>
                                    <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                                        <dt className="text-sm font-medium leading-6 text-gray-600">Identity Management</dt>
                                        <dd className="flex items-center text-sm text-gray-600 me-3">
                                        <span class="flex w-2.5 h-2.5 bg-green-500 rounded-full me-1.5 flex-shrink-0"></span>Enabled
                                        </dd>
                                    </div>
                                    <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                                        <dt className="text-sm font-medium leading-6 text-gray-600">Notifications</dt>
                                        <dd className="flex items-center text-sm text-gray-600 me-3">
                                        <span class="flex w-2.5 h-2.5 bg-green-500 rounded-full me-1.5 flex-shrink-0"></span>Enabled
                                        </dd>
                                    </div>
                                    <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                                        <dt className="text-sm font-medium leading-6 text-gray-600">Monitoring</dt>
                                        <dd className="flex items-center text-sm text-gray-600 me-3">
                                        <span class="flex w-2.5 h-2.5 bg-yellow-500 rounded-full me-1.5 flex-shrink-0"></span>Disable
                                        </dd>
                                    </div>
                                    <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                                        <dt className="text-sm font-medium leading-6 text-gray-600">Audit Log</dt>
                                        <dd className="flex items-center text-sm text-gray-600 me-3">
                                        <span class="flex w-2.5 h-2.5 bg-green-500 rounded-full me-1.5 flex-shrink-0"></span>Enabled
                                        </dd>
                                    </div>
                                    <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                                        <dt className="text-sm font-medium leading-6 text-gray-600">About</dt>
                                        <dd className="mt-1 text-sm leading-6 text-gray-600 sm:col-span-2 sm:mt-0">K8sman is an advanced platform designed to facilitate efficient management of Kubernetes clusters. Offering a secure and intuitive interface, the K8sman suite eliminates the complexity associated with direct cluster management. With robust components including a CLI client, RESTful API, and an internal agent, along with a comprehensive web interface, K8sman excels in providing granular control, enhanced security, and flexible integrations with SSO and modern monitoring systems.</dd>
                                    </div>
                                </dl>
                            </div>
                        </div>

                        {/* Redirect to Documentation */}
                        <div className="mt-6 border-t border-gray-100 pt-6 text-center">
                            <h4 className="text-lg font-medium text-gray-600 dark:text-white">Need Help?</h4>
                            <Link to="/docs" className="text-blue-600 hover:text-blue-800 dark:hover:text-blue-500">
                                Go to Documentation
                            </Link>
                        </div>
                    </div>
                </section>
            </div>
        </div>
    );
};

export default SettingsOverview;
