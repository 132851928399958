import React, { useState } from 'react';
import Pagination from './Pagination';

const DataTable = ({
  data,
  columns,
  itemsPerPage = 5,
  filterOptions = [],
  searchableKey,
}) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [selectedFilter, setSelectedFilter] = useState('all');
  const [searchTerm, setSearchTerm] = useState('');

  const isMatch = (itemValue, filterValue) => {
    if (filterValue === 'all') {
      return true;
    }
    if (typeof itemValue === 'boolean' && typeof filterValue === 'boolean') {
      return itemValue === filterValue;
    }
    if (typeof itemValue === 'string' && filterValue !== true && filterValue !== false) {
      return itemValue.toLowerCase().includes(filterValue.toLowerCase());
    }
    return false;
  };

  const filteredData = data.filter(item => {
    if (!filterOptions.length) return true;
    const filterOption = filterOptions.find(option => option.value === selectedFilter);
    const itemValue = item[filterOption?.key];
    return isMatch(itemValue, selectedFilter) &&
           item[searchableKey].toLowerCase().includes(searchTerm.toLowerCase());
  });

  const totalPages = Math.ceil(filteredData.length / itemsPerPage);
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = filteredData.slice(indexOfFirstItem, indexOfLastItem);

  return (
    <>
      <div className="flex justify-between items-center mb-4">
        {searchableKey && (
          <div className="w-3/5 mr-4">
            <input
              type="text"
              placeholder="Search by name..."
              value={searchTerm}
              onChange={e => setSearchTerm(e.target.value)}
              className="w-full appearance-none border border-gray-300 rounded py-2 px-3 text-gray-600 leading-tight focus:outline-none focus:shadow-outline"
            />
          </div>
        )}
        <div className="flex">
          {filterOptions.map((option, index) => (
            <button
              key={option.key}
              className={`py-2 px-4 focus:outline-none 
                ${selectedFilter === option.value ? 'bg-gray-300' : 'bg-white'}
                border border-gray-300 
                ${index === 0 ? 'rounded-l' : ''}
                ${index !== 0 ? 'border-l-0' : ''}  
                ${index === filterOptions.length - 1 ? 'rounded-r' : 'rounded-r-0'}`}
              onClick={() => setSelectedFilter(option.value)}
            >
              {option.text}
            </button>
          ))}
        </div>
      </div>
      <table className="min-w-full divide-y divide-gray-200 table-auto">
        <thead>
          <tr>
            {columns.map(col => (
              <th key={col.key} className="px-6 py-3 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
                {col.label}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {currentItems.map(item => (
            <tr key={item.id} className="bg-white border-b hover:bg-gray-50">
              {columns.map(col => (
                <td key={`${item.id}-${col.key}`} className="px-6 py-4 whitespace-nowrap text-sm text-gray-600">
                  {col.render ? col.render(item) : item[col.key]}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
      {totalPages > 1 && <Pagination pages={totalPages} currentPage={currentPage} setPage={setCurrentPage} />}
    </>
  );
};

export default DataTable;
