import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import Sidebar from '../../components/common/Sidebar';
import DeletePopup from '../../components/common/DeletePopup';
import { ApiClient } from '../../components/common/ApiClient';
import ActionButton from '../../components/ActionButton';

const UserEditForm = () => {
  const [user, setUser] = useState({ name: '', email: '', definitions: [], status: '' });
  const [showPopup, setShowPopup] = useState(false);
  const { userId } = useParams();
  const navigate = useNavigate();
  const { get, put, del } = ApiClient();

  useEffect(() => {
    get(`/users/${userId}`, false).then(response => {
      if (response.success) {
        setUser(response.data);
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userId]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setUser(prevState => ({ ...prevState, [name]: value }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    put(`/users/${userId}`, user).then(response => {
      if (response.success) {
        navigate('/users');
      }
    });
  };

  const handleDeleteUser = () => {
    del(`/users/${userId}`).then(response => {
      if (response.success) {
        navigate('/users');
      }
    });
  };

  return (
    <div className="container mx-auto p-3 flex">
      <Sidebar path="Users" param={{ userId }} />
      <div className="flex-grow p-4">
        <section className="mb-8">
          <div className="px-5 py-5 border border-solid rounded">
            <h3 className="text-base font-semibold leading-7 text-gray-600">User Details</h3>
            <hr className="my-4 border-gray-200" />
            <div className="grid grid-cols-1 md:grid-cols-3 gap-20">
              <div>
                <div className="mb-2">Definitions: <span className="font-bold">{user.definitions.map(def => def.name).join(', ')}</span></div>
              </div>
              <div>
                <div className="mb-2">Idp Enabled: <span className="font-bold">{user.identityProvider ? "yes" : "no"}</span></div>
              </div>
              <div>
                <div className="mb-2">Status: <span className="font-bold">{user.status ? "Active" : "Suspended"}</span></div>
                <div className="mb-2">Last Login: <span className="font-bold">{user.lastLogin}</span></div>
              </div>
            </div>
          </div>
        </section>
        <section className="mb-8">
          <div className="px-5 py-5 border border-solid rounded">
            <h3 className="text-base font-semibold leading-7 text-gray-600">Edit User</h3>
            <hr className="my-4 border-gray-200" />
            <form onSubmit={handleSubmit}>
              <div className="mb-4">
                <label htmlFor="name" className="block mb-2 text-sm font-medium text-gray-600">Name</label>
                <input
                  id="name"
                  name="name"
                  type="text"
                  value={user.name}
                  onChange={handleInputChange}
                  className="mt-1 block w-full flex-grow p-2 border rounded mb-2 text-sm text-gray-600 focus:outline-none focus:ring focus:border-indigo-300"
                  required
                />
              </div>
              <div className="mb-4">
                <label htmlFor="email" className="block mb-2 text-sm font-medium text-gray-600">Email</label>
                <input
                  id="email"
                  name="email"
                  type="email"
                  value={user.email}
                  onChange={handleInputChange}
                  className="mt-1 block w-full flex-grow p-2 border rounded mb-2 text-sm text-gray-600 focus:outline-none focus:ring focus:border-indigo-300"
                  required
                />
              </div>
              <div className="flex justify-end">
                <ActionButton type="submit" color="indigo">
                  Update User
                </ActionButton>
              </div>
            </form>
          </div>
        </section>
        <section className="mb-8">
          <div className="px-5 py-5 border border-solid border-red-500 rounded">
            <h3 className="text-lg font-semibold leading-7 text-red-500">Danger Zone</h3>
            <hr className="my-4 border-red-300" />
            <p className="text-sm text-red-600 mb-4">
              Deleting this user will permanently remove it and prevent users from logging in using this provider. Please proceed with caution as this action is irreversible.
            </p>
            <div className="flex justify-end">
              <ActionButton onClick={() => setShowPopup(true)} color="red">
                Delete User
              </ActionButton>
            </div>
          </div>
          <DeletePopup
            isOpen={showPopup}
            onClose={() => setShowPopup(false)}
            onConfirm={handleDeleteUser}
            typeText={`delete ${user?.name}`}
          />
        </section>
      </div>
    </div>
  );
};

export default UserEditForm;
