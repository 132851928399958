import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { ApiClient } from '../../components/common/ApiClient';
import Sidebar from '../../components/common/Sidebar';
import DeletePopup from '../../components/common/DeletePopup';
import ActionButton from '../../components/ActionButton';

const AgentTokens = () => {
  const [tokens, setTokens] = useState([]);
  const navigate = useNavigate();
  const [showPopup, setShowPopup] = useState(false);
  const [tokenToDelete, setTokenToDelete] = useState(null);
  const { agentId } = useParams();
  const { get, post } = ApiClient();

  useEffect(() => {
    get(`/agents/${agentId}/tokens`, false).then(response => {
        if (response.data.data) {
          setTokens(response.data.data);
        }
    });
  }, [agentId]);

  const handleCreateToken = () => {
    navigate(`/agents/${agentId}/tokens/new`);
  };

  const deleteToken = () => {
    post(`/agents/${agentId}/tokens/delete`, { tokenId: tokenToDelete.id })
      .then(response => {
          setTokens(prev => prev.filter(token => token.id !== tokenToDelete.id));
          setShowPopup(false);
      });
  };

  const disableToken = tokenId => {
    post(`/agents/${agentId}/tokens/disable`, { tokenId })
      .then(response => {
          setTokens(response.data.data);
      });
  };

  if (!tokens) {
    return <div className="text-center py-5">Loading...</div>;
  }

  return (
    <div className="container mx-auto p-3 flex">
      <Sidebar path="Agents" param={{ 'agentId': agentId }} />
      <div className="flex-grow p-4">
        <section className="mb-8">
          <div className="px-5 py-5 border border-solid rounded">
            <div className="flex justify-between items-center">
            <h3 className="text-base font-semibold leading-7 text-gray-600">Token Management</h3>
              <ActionButton onClick={handleCreateToken} color="indigo">
                <svg className="w-5 h-5 mr-2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                  <line x1="12" y1="5" x2="12" y2="19"></line>
                  <line x1="5" y1="12" x2="19" y2="12"></line>
                </svg>
                Generate Token
              </ActionButton>
            </div>
            <hr className="my-4 border-gray-200" />
            <p className="text-sm text-gray-600 mb-4">
              Breaking glass tokens are temporary credentials that, when issued, can expire after a set period, ensuring controlled access. It is advisable to invalidate the tokens after use, and here we have an issuance limit of no more than 5 tokens per user for better usage control.
            </p>
            {tokens.map(token => (
              <div key={token.id} className="mb-4 px-5 py-3 border border-solid rounded">
                <p className="text-sm">Token: <span className="font-bold">{token.token}</span></p>
                <p className="text-sm">Description: {token.description}</p>
                <p className="text-sm">Expires on: {new Date(token.expiryDate).toLocaleString()}</p>
                <p className="text-sm">Last used: {new Date(token.lastUsed).toLocaleString()}</p>
                <p className="text-sm">Created by: {token.createdBy}</p>
                <div className="flex justify-end space-x-2">
                  <button onClick={() => { setTokenToDelete(token); setShowPopup(true); }} className="text-red-500 hover:text-red-700">
                    Delete
                  </button>
                  <button onClick={() => disableToken(token.id)} className="text-yellow-500 hover:text-yellow-700">
                    Disable
                  </button>
                </div>
              </div>
            ))}
          </div>
        </section>
      </div>
      {showPopup && (
        <DeletePopup
          isOpen={showPopup}
          onClose={() => setShowPopup(false)}
          onConfirm={deleteToken}
          typeText="delete this token"
        />
      )}
    </div>
  );
};

export default AgentTokens;
