import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ApiClient } from '../../../components/common/ApiClient';
import ResourceTable from './permission/ResourceTable';
import Select from 'react-select';
import ActionButton from '../../../components/ActionButton';

const DefinitionCreateForm = () => {
  const typeOptions = [
    { label: 'Kubernetes', value: 'Kubernetes' },
    { label: 'OpenShift', value: 'OpenShift' },
    { label: 'Custom', value: 'Custom' }
  ];

  const [searchTerm, setSearchTerm] = useState('');
  const [definition, setDefinition] = useState({
    name: '',
    type: { label: 'Kubernetes', value: 'Kubernetes' },
    permissions: {}
  });
  const [step, setStep] = useState(1);
  const navigate = useNavigate();
  const { post } = ApiClient();

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setDefinition(prev => ({ ...prev, [name]: value }));
  };

  const handleSelectChange = (selectedOption) => {
    setDefinition(prev => ({ ...prev, type: selectedOption }));
  };

  const handleNext = async () => {
    if (step === 1) {
      setStep(2);
    } else {
      const { success } = await post(`/settings/definitions`, {
        ...definition,
        type: definition.type.value
      });
      if (success) {
        navigate(`/settings/definitions`);
      }
    }
  };

  const handleCancel = () => {
    navigate(`/settings/definitions`);
  };

  return (
    <section className="text-gray-600 body-font">
      <div className="container px-5 py-10 mx-auto flex flex-wrap">
        <div className="flex flex-wrap w-full">
          <div className="lg:w-1/3 md:w-1/2 md:pr-10 md:py-6">
            <div className="flex relative pb-12">
              <div className="h-full w-10 absolute inset-0 flex items-center justify-center">
                <div className="h-full w-1 bg-gray-200 pointer-events-none"></div>
              </div>
              <div className="flex-shrink-0 w-10 h-10 rounded-full bg-indigo-500 inline-flex items-center justify-center text-white relative z-10">
                <svg fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" className="w-5 h-5" viewBox="0 0 24 24">
                  <path d="M12 22s8-4 8-10V5l-8-3-8 3v7c0 6 8 10 8 10z"></path>
                </svg>
              </div>
              <div className="flex-grow pl-4">
                <h2 className="font-medium title-font text-sm text-gray-600 mb-1 tracking-wider">STEP 1: Basic Information</h2>
                <p className="text-sm">Provide basic details such as the name and type of the new definition. This foundational data is crucial for identification within the system.</p>
              </div>
            </div>
            <div className="flex relative">
              <div className="flex-shrink-0 w-10 h-10 rounded-full bg-indigo-500 inline-flex items-center justify-center text-white relative z-10">
                <svg fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" className="w-5 h-5" viewBox="0 0 24 24">
                  <path d="M22 11.08V12a10 10 0 11-5.93-9.14"></path>
                  <path d="M22 4L12 14.01l-3-3"></path>
                </svg>
              </div>
              <div className="flex-grow pl-4">
                <h2 className="font-medium title-font text-sm text-gray-600 mb-1 tracking-wider">STEP 2: Configure Permissions</h2>
                <p className="text-sm">
                  Define the specific permissions associated with this definition. Configure actionable rights for various system resources, customizing the role's capabilities.
                </p>
              </div>
            </div>
          </div>
          <div className="lg:w-2/3 md:w-1/2 rounded md:mt-0 mt-12">
            <div className="px-5 py-5 border border-solid rounded">
              <h3 className="text-base font-semibold leading-7 text-gray-600">Create New Definition</h3>
              <hr className="my-4 border-gray-200" />
              {step === 1 && (
                <>
                  <p className="text-sm text-gray-600 mb-4">
                    Initiate the creation of a new definition by entering the necessary details below. Select the appropriate type from Kubernetes, OpenShift, or Custom.
                  </p>
                  <form onSubmit={(e) => e.preventDefault()}>
                    <div className="mb-4">
                      <label htmlFor="name" className="block mb-2 text-sm font-medium text-gray-600">Name</label>
                      <input
                        id="name"
                        name="name"
                        type="text"
                        value={definition.name}
                        onChange={handleInputChange}
                        className="mt-1 block w-full flex-grow p-2 border rounded mb-2 text-sm text-gray-600 focus:outline-none focus:ring focus:border-indigo-300"
                        required
                      />
                    </div>
                    <div className="mb-4">
                      <label htmlFor="type" className="block mb-2 text-sm font-medium text-gray-600">Type</label>
                      <Select
                        options={typeOptions}
                        value={definition.type}
                        onChange={handleSelectChange}
                        placeholder="Select a type"
                        styles={{
                          control: (baseStyles, state) => ({
                            ...baseStyles,
                            borderColor: '#d1d5db',
                            boxShadow: state.isFocused ? '0 0 0 3px #a5b4fc' : 'none',
                            '&:hover': {
                              borderColor: state.isFocused ? '#d1d5db' : '#9ca3af',
                            },
                          }),
                          option: (baseStyles, state) => ({
                            ...baseStyles,
                            backgroundColor: state.isSelected ? '#4f46e5' : state.isFocused ? '#e0e7ff' : '#ffffff',
                          }),
                          singleValue: (baseStyles) => ({
                            ...baseStyles,
                            color: '#6b7280',
                          }),
                          placeholder: (baseStyles) => ({
                            ...baseStyles,
                            color: '#6b7280', // text-gray-600
                          }),
                          input: (baseStyles) => ({
                            ...baseStyles,
                            color: '#4b5563', // text-gray-700
                          }),
                        }}
                      />
                    </div>
                    <div className="flex justify-end">
                      <ActionButton onClick={handleCancel} color="indigo" styleType="transparent">
                        Cancel
                      </ActionButton>
                      <ActionButton onClick={handleNext} color="indigo">
                        Next Step
                      </ActionButton>
                    </div>
                  </form>
                </>
              )}
              {step === 2 && (
                <div>
                  <div className="mb-4">
                    <ResourceTable
                      searchTerm={searchTerm}
                      setSearchTerm={setSearchTerm}
                      permissions={definition.permissions}
                      onTogglePermission={(newPermissions) => setDefinition({ ...definition, permissions: newPermissions })}
                    />
                  </div>
                  <div className="flex justify-end mt-4">
                    <ActionButton onClick={() => setStep(1)} color="indigo" styleType="transparent">
                      Previous
                    </ActionButton>
                    <ActionButton onClick={handleNext} color="indigo">
                      Save and Finish
                    </ActionButton>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default DefinitionCreateForm;
