import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import DataTable from '../../components/common/DataTable';
import { ApiClient } from '../../components/common/ApiClient';
import ActionButton from '../../components/ActionButton';

const UsersTable = () => {
  const navigate = useNavigate();
  const [users, setUsers] = useState(null);
  const { get } = ApiClient();

  useEffect(() => {
    const fetchData = async () => {
      const response = await get(`/users`, false);
      if (response?.success) {
        const modifiedUsers = response.data.data.users.map(user => ({
          ...user,
          definitions: user.definitions.map(def => def.name).join(', ')
        }));
        setUsers(modifiedUsers);
      }
    };

    fetchData();
  }, [get]);

  const filterOptions = [
    { key: 'status', value: true, text: 'Active' },
    { key: 'status', value: false, text: 'Suspended' },
    { key: 'status', value: 'all', text: 'All' },
  ];

  const searchableKey = 'name';

  const columns = [
    { key: 'name', label: 'Name' },
    { key: 'email', label: 'Email', align: 'center' },
    { key: 'definitions', label: 'Definitions', align: 'center' },
    {
      key: 'identityProvider',
      label: 'Idp Enabled',
      align: 'center',
      render: (item) => item.identityProvider ? (
        <span>yes</span>
      ) : (
        <span>no</span>
      ),
    },
    {
      key: 'status',
      label: 'Status',
      align: 'center',
      render: (item) => item.status ? (
        <span className="inline-flex items-center rounded bg-indigo-50 px-2 py-1 text-xs font-medium text-indigo-700 ring-1 ring-inset ring-indigo-700/10">Active</span>
      ) : (
        <span className="inline-flex items-center rounded bg-amber-50 px-2 py-1 text-xs font-medium text-amber-700 ring-1 ring-inset ring-amber-700/10">Suspended</span>
      ),
    },
    {
      key: 'lastUpdated',
      label: 'Last Updated',
      render: item => new Date(item.lastUpdated).toLocaleString(),
    },
    {
      key: 'options',
      label: 'Options',
      align: 'center',
      render: (item) => (
        <>
          <button onClick={() => navigate(`/users/${item.id}/details`)} className="text-indigo-600 hover:text-indigo-800 mr-4">
            Details
          </button>
          <button onClick={() => navigate(`/users/${item.id}/definitions`)} className="text-indigo-600 hover:text-indigo-800">
            Definitions
          </button>
        </>
      )
    },
  ];

  const addUser = () => {
    navigate(`/users/new`);
  };

  if (!users) {
    return <div className="text-center py-5">Loading...</div>;
  }

  return (
    <div className="container mx-auto p-3 flex">
      <div className="flex-grow p-4">
        <section className="mb-8">
          <div className="px-5 py-5 border border-solid rounded scroll-mt-20">
            <div className="flex justify-between items-center">
              <h3 className="text-base font-semibold leading-7 text-gray-600">Platform Users</h3>
              <ActionButton onClick={addUser} color="indigo">
                <svg className="w-5 h-5 me-2 -ms-1" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="#ffffff" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"><line x1="12" y1="5" x2="12" y2="19"></line><line x1="5" y1="12" x2="19" y2="12"></line></svg>
                New User
              </ActionButton>
            </div>
            <hr className="my-4 border-gray-200" />
            <DataTable
              data={users}
              columns={columns}
              itemsPerPage={5}
              filterOptions={filterOptions}
              searchableKey={searchableKey}
            />
          </div>
        </section>
      </div>
    </div>
  );
};

export default UsersTable;
