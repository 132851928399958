import React from 'react';
import { Routes, Route } from 'react-router-dom';

import Header from '../../pages/Header';
import Dashboard from '../../pages/Dashboard';
import AgentDetails from '../../pages/agents/AgentDetails';
import AgentAudit from '../../pages/agents/AgentAudit';
import AgentsTable from '../../pages/agents/AgentsTable';
import AgentCreateForm from '../../pages/agents/AgentCreateForm';
import AgentTokens from '../../pages/agents/AgentTokens';
import AgentTokensCreateForm from '../../pages/agents/AgentTokensCreateForm';
import UsersTable from '../../pages/users/UsersTable';
import UserDefinition from '../../pages/users/UserDefinition';
import UserEditForm from '../../pages/users/UserEditForm';
import UserCreateForm from '../../pages/users/UserCreateForm';
import SettingsOverview from '../../pages/settings/SettingsOverview';
import DefinitionTable from '../../pages/settings/definition/DefinitionTable';
import DefinitionPermissions from '../../pages/settings/definition/DefinitionPermissions';
import DefinitionDetail from '../../pages/settings/definition/DefinitionDetail';
import DefinitionCreateForm from '../../pages/settings/definition/DefinitionCreateForm';
import ProviderTable from '../../pages/settings/identity-manager/ProviderTable';
import ProviderSamlv2CreateForm from '../../pages/settings/identity-manager/ProviderSamlv2CreateForm';
import ProviderSamlv2Detail from '../../pages/settings/identity-manager/ProviderSamlv2Detail';
import ProviderSamlvsMappings from '../../pages/settings/identity-manager/ProviderSamlvsMappings';
import MonitoringPage from '../../pages/settings/monitoring/Monitoring';
import NotificationPage from '../../pages/settings/notifications/Notification';
import BillingPage from '../../pages/settings/billing/Billing';
import ProfileEditForm from '../../pages/profile/ProfileEditForm';
import Subscriptions from '../../pages/settings/billing/Subscriptions';

const AuthenticatedRoutes = () => {
  return (
    <>
      <Header />
      <Routes>
        <Route path="/dashboard" element={<Dashboard />} />
        <Route path="/agents" element={<AgentsTable />} />
        <Route path="/agents/new" element={<AgentCreateForm />} />
        <Route path="/agents/:agentId/details" element={<AgentDetails />} />
        <Route path="/agents/:agentId/audit" element={<AgentAudit />} />
        <Route path="/agents/:agentId/tokens" element={<AgentTokens />} />
        <Route path="/agents/:agentId/tokens/new" element={<AgentTokensCreateForm />} />
        <Route path="/users" element={<UsersTable />} />
        <Route path="/users/new" element={<UserCreateForm />} />
        <Route path="/users/:userId/details" element={<UserEditForm />} />
        <Route path="/users/:userId/definitions" element={<UserDefinition />} />
        <Route path="/settings" element={<SettingsOverview />} />
        <Route path="/settings/definitions" element={<DefinitionTable />} />
        <Route path="/settings/definitions/new" element={<DefinitionCreateForm />} />
        <Route path="/settings/definitions/:definitionId/details" element={<DefinitionDetail />} />
        <Route path="/settings/definitions/:definitionId/permissions" element={<DefinitionPermissions />} />
        <Route path="/settings/identity-manager" element={<ProviderTable />} />
        <Route path="/settings/identity-manager/new" element={<ProviderSamlv2CreateForm />} />
        <Route path="/settings/identity-manager/:providerId/details" element={<ProviderSamlv2Detail />} />
        <Route path="/settings/identity-manager/:providerId/mappings" element={<ProviderSamlvsMappings />} />
        <Route path="/settings/monitoring" element={<MonitoringPage />} />
        <Route path="/settings/notifications" element={<NotificationPage />} />
        <Route path="/settings/billing" element={<BillingPage />} />
        <Route path="/settings/billing/subscriptions/:currentProductId" element={<Subscriptions />} />
        <Route path="/profile" element={<ProfileEditForm />} />
      </Routes>
    </>
  );
};

export default AuthenticatedRoutes;
