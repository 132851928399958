import React, { useState, useEffect } from 'react';
import Sidebar from '../../../components/common/Sidebar';
import ActionButton from '../../../components/ActionButton';
import { ApiClient } from '../../../components/common/ApiClient';

const NotificationPage = () => {
  const [notificationConfig, setNotificationConfig] = useState({
    emails: '',
    emailsEnabled: false,
    customWebhookUrl: '',
    webhookEnabled: false,
    slackConfigured: false,
    slackEnabled: false,
  });

  const { get, post, put } = ApiClient();

  useEffect(() => {
    get('/settings/notifications/config', false).then(response => {
      if (response.success) {
        setNotificationConfig(response.data);
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);



  const handleSlackIntegration = () => {
    setNotificationConfig((prev) => ({
      ...prev,
      slackConfigured: true,
    }));
    window.open('https://hooks.slack.com/services/', '_blank');
  };

  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;
    setNotificationConfig((prev) => ({
      ...prev,
      [name]: type === 'checkbox' ? checked : value,
    }));
  };

  const handleSaveConfiguration = (section) => {
    post(`/settings/notifications/config/${section}`, {
      [section]: notificationConfig[section],
      [`${section}Enabled`]: notificationConfig[`${section}Enabled`]
    }).then(response => {
      if (response.success) {
        console.log(`${section} configuration saved:`, notificationConfig);
      }
    });
  };

  const toggleNotification = (name) => {
    const section = name.replace('Enabled', '');
    const newValue = !notificationConfig[name];
    put(`/settings/notifications/config/${section}/status`, { enabled: newValue }).then(response => {
      if (response.success) {
        setNotificationConfig((prev) => ({
          ...prev,
          [name]: newValue,
        }));
      }
    });
  };

  return (
    <div className="container mx-auto p-3 flex">
      <Sidebar path="Settings" param={[]} />
      <div className="flex-grow p-4 space-y-8">
        
        <section className="border rounded bg-white p-5">
          <h3 className="text-base font-semibold leading-7 text-gray-600">Email Notifications</h3>
          <p className="mt-1 text-sm text-gray-600">Enter multiple email addresses separated by commas.</p>
          <hr className="my-4 border-gray-200" />
          <div className="space-y-4">
            <div>
              <label htmlFor="emails" className="block text-sm font-medium text-gray-600">Emails</label>
              <input
                id="emails"
                name="emails"
                type="text"
                value={notificationConfig.emails}
                onChange={handleInputChange}
                className="mt-1 block w-full border border-gray-300 rounded shadow-sm py-2 px-3 focus:outline-none sm:text-sm"
                placeholder="Enter email addresses"
                disabled={!notificationConfig.emailsEnabled}
              />
            </div>
            <div className="flex justify-between items-center">
              <div className="flex items-center">
                <label htmlFor="emailsEnabled" className="mr-3 block text-sm font-medium text-gray-600">Enable</label>
                <label htmlFor="emailsEnabled" className="relative inline-flex items-center cursor-pointer">
                  <input
                    type="checkbox"
                    id="emailsEnabled"
                    name="emailsEnabled"
                    className="sr-only peer"
                    checked={notificationConfig.emailsEnabled}
                    onChange={() => toggleNotification('emailsEnabled')}
                  />
                  <div className="w-9 h-5 bg-gray-200 peer-focus:outline-none rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-4 after:w-4 after:transition-all peer-checked:bg-indigo-600"></div>
                </label>
              </div>
              <ActionButton onClick={() => handleSaveConfiguration('emails')} color="indigo">
                Update
              </ActionButton>
            </div>
          </div>
        </section>

        <section className="border rounded bg-white p-5">
          <h3 className="text-base font-semibold leading-7 text-gray-600">Slack Integration</h3>
          <p className="mt-1 text-sm text-gray-600">Get important notifications for your services on Slack.</p>
          <hr className="my-4 border-gray-200" />
          <div className="space-y-4">
            <div className="flex items-center mt-2">
              <button
                className="py-2 px-4 text-sm font-medium text-white bg-indigo-600 rounded hover:bg-indigo-700"
                onClick={handleSlackIntegration}
                disabled={!notificationConfig.slackEnabled}
              >
                Connect Slack
              </button>
              {notificationConfig.slackConfigured && (
                <span className="ml-3 text-sm text-green-600">Slack is configured</span>
              )}
            </div>
            <div className="flex justify-between items-center">
              <div className="flex items-center">
                <label htmlFor="slackEnabled" className="mr-3 block text-sm font-medium text-gray-600">Enable</label>
                <label htmlFor="slackEnabled" className="relative inline-flex items-center cursor-pointer">
                  <input
                    type="checkbox"
                    id="slackEnabled"
                    name="slackEnabled"
                    className="sr-only peer"
                    checked={notificationConfig.slackEnabled}
                    onChange={() => toggleNotification('slackEnabled')}
                  />
                  <div className="w-9 h-5 bg-gray-200 peer-focus:outline-none rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-4 after:w-4 after:transition-all peer-checked:bg-indigo-600"></div>
                </label>
              </div>
              <ActionButton onClick={() => handleSaveConfiguration('slack')} color="indigo">
                Update
              </ActionButton>
            </div>
          </div>
        </section>

        <section className="border rounded bg-white p-5">
          <h3 className="text-base font-semibold leading-7 text-gray-600">Webhook Integration</h3>
          <p className="mt-1 text-sm text-gray-600">Set up a custom webhook to receive notifications.</p>
          <hr className="my-4 border-gray-200" />
          <div className="space-y-4">
            <div>
              <label htmlFor="customWebhookUrl" className="block text-sm font-medium text-gray-600">
                Custom Webhook URL
              </label>
              <input
                id="customWebhookUrl"
                name="customWebhookUrl"
                type="text"
                value={notificationConfig.customWebhookUrl}
                onChange={handleInputChange}
                className="mt-1 block w-full border border-gray-300 rounded shadow-sm py-2 px-3 focus:outline-none sm:text-sm"
                placeholder="Enter your custom webhook URL"
                disabled={!notificationConfig.webhookEnabled}
              />
            </div>
            <div className="flex justify-between items-center">
              <div className="flex items-center">
                <label htmlFor="webhookEnabled" className="mr-3 block text-sm font-medium text-gray-600">Enable</label>
                <label htmlFor="webhookEnabled" className="relative inline-flex items-center cursor-pointer">
                  <input
                    type="checkbox"
                    id="webhookEnabled"
                    name="webhookEnabled"
                    className="sr-only peer"
                    checked={notificationConfig.webhookEnabled}
                    onChange={() => toggleNotification('webhookEnabled')}
                  />
                  <div className="w-9 h-5 bg-gray-200 peer-focus:outline-none rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-4 after:w-4 after:transition-all peer-checked:bg-indigo-600"></div>
                </label>
              </div>
              <ActionButton onClick={() => handleSaveConfiguration('customWebhookUrl')} color="indigo">
                Update
              </ActionButton>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
};

export default NotificationPage;
