import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Sidebar from '../../../components/common/Sidebar';
import { ApiClient } from '../../../components/common/ApiClient';
import DataTable from '../../../components/common/DataTable';
import ActionButton from '../../../components/ActionButton';

const ProviderTable = ({ customerId }) => {
  const [providers, setProviders] = useState(null);
  const navigate = useNavigate();
  const { get, put } = ApiClient();

  useEffect(() => {
    get(`/settings/identity-manager/providers/saml`, false).then(response => {
      if (response.success) {
        setProviders(response.data.data);
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customerId]);

  if (!providers) return <div className="text-center py-5">Loading...</div>; // Loading message

  const toggleProviderStatus = async (providerId) => {
    const provider = providers.find(p => p.id === providerId);
    if (provider) {
      put(`/saml/providers/${providerId}/status`, { enabled: !provider.enabled })
        .then(() => {
          const updatedProviders = providers.map(p =>
            p.id === providerId ? { ...p, enabled: !p.enabled } : p
          );
          setProviders(updatedProviders);
        });
    }
  };

  const columns = [
    {
      key: 'enabled',
      label: 'Enabled',
      align: 'center',
      render: (item) => (
        <div className="select-none transition duration-200 ease-in">
          <label htmlFor={`toggle-${item.id}`} className="relative inline-flex items-center cursor-pointer">
            <input
              type="checkbox"
              value=""
              id={`toggle-${item.id}`}
              className="sr-only peer"
              checked={item.enabled}
              onChange={() => toggleProviderStatus(item.id)}
            />
            <div className="w-9 h-5 bg-gray-200 peer-focus:outline-none rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-4 after:w-4 after:transition-all peer-checked:bg-indigo-600"></div>
          </label>
        </div>
      )
    },
    { key: 'name', label: 'Name' },
    {
      key: 'lastUpdated',
      label: 'Last Updated',
      render: item => new Date(item.lastUpdated).toLocaleString(),
    },
    {
      key: 'options',
      label: 'Options',
      align: 'center',
      render: (item) => (
        <>
          <button onClick={() => navigate(`/settings/identity-manager/${item.id}/details`)} className="text-indigo-600 hover:text-indigo-800 mr-4">
            Details
          </button>
          <button onClick={() => navigate(`/settings/identity-manager/${item.id}/mappings`)} className="text-indigo-600 hover:text-indigo-800">
            Mappings
          </button>
        </>
      )
    },
  ];

  const handleAddNewProvider = () => {
    navigate('/settings/identity-manager/new');
  };


  return (
    <div className="container mx-auto p-3 flex">
      <Sidebar path="Settings" param={[]} />
      <div className="flex-grow p-4">
        <section className="mb-8">
          <div className="px-5 py-5 border border-solid rounded scroll-mt-20">
            <div className="flex justify-between items-center">
              <h3 className="text-base font-semibold leading-7 text-gray-600">Identity Management</h3>
              <ActionButton onClick={handleAddNewProvider} color="indigo">
                <svg className="w-5 h-5 me-2" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="#ffffff" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                  <line x1="12" y1="5" x2="12" y2="19"></line>
                  <line x1="5" y1="12" x2="19" y2="12"></line>
                </svg>
                New Provider
              </ActionButton>
            </div>
            <hr className="my-4 border-gray-200" />
            <p className="mb-4 text-sm text-gray-600">
              Configuring your SAML providers is a key step to ensure secure and streamlined identity management on the platform. With SAML, we simplify access to Kubernetes resources, enabling your organization's users to leverage their existing identities for a unified login experience. For detailed understanding and guidance on properly configuring SAML, visit <a href="/docs/#providers-saml" className="text-indigo-600 hover:text-indigo-800">this documentation section</a>.
            </p>
            <div className="overflow-x-auto">
              <DataTable
                data={providers}
                columns={columns}
                itemsPerPage={5}
              />
            </div>
          </div>
        </section>
      </div>
    </div>
  );
};

export default ProviderTable;
