import React, { useState, useEffect } from 'react';
import { ApiClient } from '../../components/common/ApiClient';
import Select from 'react-select';

const UserPermissionConfig = ({ mode, userDetail, updateSelectedDefinitions }) => {
    const [definitions, setDefinitions] = useState([]);
    const [selectedDefinitions, setSelectedDefinitions] = useState(mode === "update" ? userDetail.definitions || [] : []);
    const [selectedDefinition, setSelectedDefinition] = useState(null);
    const apiClient = ApiClient();

    const fetchAllDefinitions = async () => {
      const response = await apiClient.get('/settings/definitions', false);
      if (response.success) {
          const options = response.data.definitions.map(def => ({ label: def.name, value: def.id }));
          setDefinitions(options);
      }
  };

  const fetchDefinitionsForUpdate = async () => {
      const response = await apiClient.get(`/users/${userDetail.id}/definitions`, false);
      if (response.success) {
          const linkedDefinitionsIds = new Set(response.data.definitions.map(def => def.id));
          const allDefinitionsResponse = await apiClient.get('/settings/definitions', false);
          if (allDefinitionsResponse.success) {
              const availableDefinitions = allDefinitionsResponse.data.definitions.filter(def => !linkedDefinitionsIds.has(def.id)).map(def => ({ label: def.name, value: def.id }));
              setDefinitions(availableDefinitions);
              setSelectedDefinitions(response.data.definitions.map(def => ({ label: def.name, value: def.id })));
          }
      }
  };

    useEffect(() => {
        if (mode === "create") {
            fetchAllDefinitions(); 
        } else if (userDetail && userDetail.id) {
            fetchDefinitionsForUpdate();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [userDetail?.id, mode]);

  
    const handleSelectDefinition = (selectedOption) => {
        const definition = definitions.find(def => def.value === selectedOption.value);
        if (definition) {
            const newSelectedDefinitions = [...selectedDefinitions, definition];
            setSelectedDefinitions(newSelectedDefinitions);
            setDefinitions(definitions.filter(def => def.value !== selectedOption.value));
            updateSelectedDefinitions(newSelectedDefinitions.map(def => ({ id: def.value, name: def.label })));
            setSelectedDefinition(null);
        }
    };

    const handleDeleteDefinition = (definitionId) => {
        const updatedSelectedDefinitions = selectedDefinitions.filter(def => def.value !== definitionId);
        const definitionToRestore = selectedDefinitions.find(def => def.value === definitionId);
        setSelectedDefinitions(updatedSelectedDefinitions);
        setDefinitions([...definitions, definitionToRestore].sort((a, b) => a.label.localeCompare(b.label)));
        updateSelectedDefinitions(updatedSelectedDefinitions.map(def => ({ id: def.value, name: def.label })));
    };

    return (
        <div>
            <p className="mb-4">Manage user permissions by assigning specific definitions that dictate their capabilities within the system.</p>
            <div className="flex flex-wrap -mx-3 mb-6">
                <div className="w-full md:w-1/2 px-3 mb-6 md:mb-0">
                    <label htmlFor="email" className="block mb-2 font-medium text-gray-600">Email</label>
                    <p>{userDetail.email}</p>
                </div>
                <div className="w-full md:w-1/2 px-3">
                    <label className="block mb-2 font-medium" htmlFor="definitionDropdown">Add Definition</label>
                    <Select
                        options={definitions}
                        value={selectedDefinition}
                        onChange={handleSelectDefinition}
                        placeholder="Select a definition"
                        styles={{
                          control: (baseStyles, state) => ({
                            ...baseStyles,
                            borderColor: '#d1d5db',
                            boxShadow: state.isFocused ? '0 0 0 3px #a5b4fc' : 'none',
                            '&:hover': {
                              borderColor: state.isFocused ? '#d1d5db' : '#9ca3af',
                            },
                          }),
                          option: (baseStyles, state) => ({
                            ...baseStyles,
                            backgroundColor: state.isSelected ? '#4f46e5' : state.isFocused ? '#e0e7ff' : '#ffffff',

                          }),
                          singleValue: (baseStyles) => ({
                            ...baseStyles,
                            color: '#6b7280',
                          }),
                          placeholder: (baseStyles) => ({
                            ...baseStyles,
                            color: '#6b7280', // text-gray-600
                          }),
                          input: (baseStyles) => ({
                            ...baseStyles,
                            color: '#4b5563', // text-gray-700
                          }),
                        }}
                      />
                </div>
            </div>
            {selectedDefinitions.length > 0 ? (
                <div className="overflow-x-auto">
                    <table className="min-w-full table-auto">
                        <thead className="bg-gray-50">
                            <tr>
                                <th className="px-5 py-3 font-bold text-left uppercase bg-white border-b">Definition</th>
                                <th className="px-5 py-3 font-bold text-left uppercase bg-white border-b text-center">Actions</th>
                            </tr>
                        </thead>
                        <tbody className="bg-white divide-y divide-gray-200">
                            {selectedDefinitions.map((def) => (
                                <tr key={def.value}>
                                    <td className="px-5 py-5">{def.label}</td>
                                    <td className="px-5 py-5 text-center">
                                        <button onClick={() => handleDeleteDefinition(def.value)} className="text-blue-500 hover:text-blue-700">
                                            Remove
                                        </button>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            ) : (
                <div className="flex items-center justify-center p-4 text-sm text-gray-600 rounded bg-gray-50" role="alert">
                    <div className="text-center">
                        <span className="font-medium">Alert!</span> Select at least one definition to proceed.
                    </div>
                </div>
            )}
        </div>
    );
};

export default UserPermissionConfig;
