import React, { useState, useEffect } from 'react';
import Sidebar from '../../../components/common/Sidebar';
import Select from 'react-select';
import ActionButton from '../../../components/ActionButton';
import { ApiClient } from '../../../components/common/ApiClient';

const MonitoringPage = () => {
  const [integrationConfig, setIntegrationConfig] = useState({
    providerType: { label: 'Prometheus', value: 'Prometheus' },
    endpoint: '',
    apiKey: '',
    providerEnabled: false,
  });

  const providerOptions = [
    { label: 'Prometheus', value: 'Prometheus' },
    { label: 'DataDog', value: 'DataDog' },
    { label: 'New Relic', value: 'NewRelic' },
    { label: 'OpenTelemetry', value: 'OpenTelemetry' }
  ];

  const { get, post, put } = ApiClient();

  useEffect(() => {
    // Load initial configuration from the backend
    get('/settings/monitoring/config', false).then(response => {
      if (response.success) {
        setIntegrationConfig(response.data);
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setIntegrationConfig(prev => ({ ...prev, [name]: value }));
  };

  const handleSelectChange = (selectedOption) => {
    setIntegrationConfig(prev => ({ ...prev, providerType: selectedOption }));
  };

  const handleSaveConfiguration = () => {
    post(`/settings/monitoring/config/${integrationConfig.providerType.value}`, {
      endpoint: integrationConfig.endpoint,
      apiKey: integrationConfig.apiKey,
      providerEnabled: integrationConfig.providerEnabled
    }).then(response => {
      if (response.success) {
        console.log('Configuration saved:', integrationConfig);
      }
    });
  };

  const toggleProviderStatus = () => {
    const newValue = !integrationConfig.providerEnabled;
    put(`/settings/monitoring/config/${integrationConfig.providerType.value}/status`, { enabled: newValue }).then(response => {
      if (response.success) {
        setIntegrationConfig(prev => ({ ...prev, providerEnabled: newValue }));
      }
    });
  };

  return (
    <div className="container mx-auto p-3 flex">
      <Sidebar path="Settings" param={[]} />
      <div className="flex-grow p-4 space-y-8">
        <section className="border rounded bg-white p-5">
          <h3 className="text-base font-semibold leading-7 text-gray-600">Monitoring & Integration</h3>
          <p className="mt-1 text-sm text-gray-600">
            Configure integration with monitoring services to send logs from K8sman agents. Set up your service to receive OpenTelemetry data for detailed analytics and insights.
          </p>
          <hr className="my-4 border-gray-200" />
          <form className="space-y-4">
            <div>
              <label htmlFor="providerType" className="block text-sm font-medium text-gray-600">Provider Type</label>
              <Select
                id="providerType"
                options={providerOptions}
                value={integrationConfig.providerType}
                onChange={handleSelectChange}
                styles={{
                  control: (baseStyles, state) => ({
                    ...baseStyles,
                    borderColor: '#d1d5db',
                    boxShadow: state.isFocused ? '0 0 0 3px #a5b4fc' : 'none',
                    '&:hover': {
                      borderColor: state.isFocused ? '#d1d5db' : '#9ca3af',
                    },
                  }),
                  option: (baseStyles, state) => ({
                    ...baseStyles,
                    backgroundColor: state.isSelected ? '#4f46e5' : state.isFocused ? '#e0e7ff' : '#ffffff',
                  }),
                  singleValue: (baseStyles) => ({
                    ...baseStyles,
                    color: '#6b7280',
                  }),
                  placeholder: (baseStyles) => ({
                    ...baseStyles,
                    color: '#6b7280', // text-gray-600
                  }),
                  input: (baseStyles) => ({
                    ...baseStyles,
                    color: '#4b5563', // text-gray-700
                  }),
                }}
                isDisabled={!integrationConfig.providerEnabled}
              />
            </div>
            <div>
              <label htmlFor="endpoint" className="block text-sm font-medium text-gray-600">Endpoint</label>
              <input
                id="endpoint"
                name="endpoint"
                type="text"
                value={integrationConfig.endpoint}
                onChange={handleInputChange}
                className="mt-1 block w-full border border-gray-300 rounded shadow-sm py-2 px-3 focus:outline-none sm:text-sm"
                placeholder="https://api.monitoring-service.com"
                disabled={!integrationConfig.providerEnabled}
              />
            </div>
            <div>
              <label htmlFor="apiKey" className="block text-sm font-medium text-gray-600">API Key</label>
              <input
                id="apiKey"
                name="apiKey"
                type="password"
                value={integrationConfig.apiKey}
                onChange={handleInputChange}
                className="mt-1 block w-full border border-gray-300 rounded shadow-sm py-2 px-3 focus:outline-none sm:text-sm"
                placeholder="Enter your API key"
                disabled={!integrationConfig.providerEnabled}
              />
            </div>
            <div className="flex justify-between items-center">
              <div className="flex items-center">
                <label htmlFor="providerEnabled" className="mr-3 block text-sm font-medium text-gray-600">Enable</label>
                <label htmlFor="providerEnabled" className="relative inline-flex items-center cursor-pointer">
                  <input
                    type="checkbox"
                    id="providerEnabled"
                    name="providerEnabled"
                    className="sr-only peer"
                    checked={integrationConfig.providerEnabled}
                    onChange={toggleProviderStatus}
                  />
                  <div className="w-9 h-5 bg-gray-200 peer-focus:outline-none rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-4 after:w-4 after:transition-all peer-checked:bg-indigo-600"></div>
                </label>
              </div>
              <ActionButton onClick={handleSaveConfiguration} color="indigo">
                Update
              </ActionButton>
            </div>
          </form>
          <div className="mt-6 bg-indigo-100 p-4 rounded">
            <h4 className="text-base font-medium text-gray-800">Supported Services</h4>
            <ul className="list-disc pl-5 text-sm text-gray-800">
              <li>Prometheus: A powerful monitoring system and time series database.</li>
              <li>DataDog: Cloud-based monitoring and analytics platform.</li>
              <li>New Relic: Comprehensive suite of monitoring tools.</li>
              <li>OpenTelemetry: Standard for collecting observability data from cloud-native software.</li>
            </ul>
          </div>
        </section>
      </div>
    </div>
  );
};

export default MonitoringPage;
