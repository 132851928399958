import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ApiClient } from '../../../components/common/ApiClient';
import ProviderSamlv2CertificatePopup from './ProviderSamlv2CertificatePopup';
import ActionButton from '../../../components/ActionButton';

const ProviderSamlv2CreateForm = () => {
  const [providerDetails, setProviderDetails] = useState({
    name: '',
    description: '',
    idpEndpoint: '',
    issuerUrl: '',
    nameIdFormat: 'urn:oasis:names:tc:SAML:2.0:nameid-format:persistent',
    verificationKey: '',
    buttonText: 'Login with SAML',
    buttonImageURL: '',
    idpInitiatedLogin: false,
    debugEnabled: false
  });
  const [step, setStep] = useState(1);
  const navigate = useNavigate();
  const { post } = ApiClient();
  const [showCertificatePopup, setShowCertificatePopup] = useState(false);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setProviderDetails(prev => ({ ...prev, [name]: value }));
  };

  const handleNext = async () => {
    if (step === 1) {
      const response = await post(`/settings/identity-manager/providers/saml`, providerDetails);
      if (response.success) {
        setProviderDetails(response.data.data);
      }
      setStep(2);
    }
  };

  const handleCancel = () => {
    navigate(`/settings/identity-manager`);
  };

  const handleUpdateCertificate = (newCertificate) => {
    setProviderDetails(prev => ({
      ...prev,
      certificateEncoded: newCertificate
    }));
    setShowCertificatePopup(false);
  };

  return (
    <section className="text-gray-600 body-font">
      <div className="container px-5 py-10 mx-auto flex flex-wrap">
        <div className="flex flex-wrap w-full">
          <div className="lg:w-2/5 md:w-1/2 md:pr-10 md:py-6">
            <div className="flex relative pb-12">
              <div className="h-full w-10 absolute inset-0 flex items-center justify-center">
                <div className="h-full w-1 bg-gray-200 pointer-events-none"></div>
              </div>
              <div className="flex-shrink-0 w-10 h-10 rounded-full bg-indigo-500 inline-flex items-center justify-center text-white relative z-10">
                <svg fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" className="w-5 h-5" viewBox="0 0 24 24">
                  <path d="M12 22s8-4 8-10V5l-8-3-8 3v7c0 6 8 10 8 10z"></path>
                </svg>
              </div>
              <div className="flex-grow pl-4">
                <h2 className="font-medium title-font text-sm text-gray-600 mb-1 tracking-wider">STEP 1: Define Identity Provider</h2>
                <p className="text-sm">Enter the details of your Identity Provider (IdP) configuration. Ensure that all information accurately reflects the intended setup and use.</p>
              </div>
            </div>
            <div className="flex relative">
              <div className="flex-shrink-0 w-10 h-10 rounded-full bg-indigo-500 inline-flex items-center justify-center text-white relative z-10">
                <svg fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" className="w-5 h-5" viewBox="0 0 24 24">
                  <path d="M22 11.08V12a10 10 0 11-5.93-9.14"></path>
                  <path d="M22 4L12 14.01l-3-3"></path>
                </svg>
              </div>
              <div className="flex-grow pl-4">
                <h2 className="font-medium title-font text-sm text-gray-600 mb-1 tracking-wider">STEP 2: Identity Provider Information</h2>
                <p className="text-sm">Review and confirm the details of your Identity Provider (IdP) configuration. Make sure that all information is correct before finalizing the setup.</p>
              </div>
            </div>
          </div>
          <div className="lg:w-3/5 md:w-1/2 rounded md:mt-0 mt-12">
            <div className="px-5 py-5 border border-solid rounded">
              <h3 className="antialiased font-medium text-lg leading-6 text-gray-600">Register a SamlV2 configuration</h3>
              <hr className="my-4 border-gray-200" />
              {step === 1 && (
                <>
                  <p className="text-sm text-gray-500 mb-4">
                    Configure the details for the new SamlV2 protocol, this is essential to enable the secure an authorized users acess the platform. Ensure all information accurately reflects the intended usage, visit <a href="/docs/#providers-saml" className="text-indigo-600 hover:text-indigo-800">this documentation section</a>.
                  </p>

                  <div className="mb-4">
                    <label htmlFor="name" className="block text-sm font-medium text-gray-600">Name</label>
                    <input
                      id="name"
                      name="name"
                      type="text"
                      required
                      className="mt-1 block w-full flex-grow p-2 border rounded mb-2 text-sm text-gray-600 focus:outline-none focus:ring focus:border-indigo-300"
                      value={providerDetails.name}
                      onChange={handleInputChange}
                    />
                  </div>
                  <div className="mb-4">
                    <label htmlFor="idpEndpoint" className="block text-sm font-medium text-gray-600">IdP Endpoint</label>
                    <input
                      id="idpEndpoint"
                      name="idpEndpoint"
                      type="url"
                      required
                      className="mt-1 block w-full flex-grow p-2 border rounded mb-2 text-sm text-gray-600 focus:outline-none focus:ring focus:border-indigo-300"
                      value={providerDetails.idpEndpoint}
                      onChange={handleInputChange}
                    />
                  </div>
                  <div className="mb-4">
                    <label htmlFor="nameIdFormat" className="block text-sm font-medium text-gray-600">NameID Format</label>
                    <input
                      type="text"
                      id="nameIdFormat"
                      name="nameIdFormat"
                      required
                      className="mt-1 block w-full flex-grow p-2 border rounded mb-2 text-sm text-gray-600 focus:outline-none focus:ring focus:border-indigo-300"
                      value={providerDetails.nameIdFormat}
                      onChange={handleInputChange}
                      placeholder="Enter NameID Format"
                    />
                  </div>
                  <div className="mb-4">
                    <label className="block mb-2 text-sm font-medium text-gray-600">Certificate of Digital Signature</label>
                    <div className="p-6 border border-indigo-200 rounded shadow-sm bg-indigo-50">
                      <p className="text-sm text-gray-600">
                        A digital signature certificate is essential for authenticating your identity provider in SAML v2 processes. To upload your initial certificate,
                        <button type="button" onClick={() => setShowCertificatePopup(true)} className="ml-2 inline text-indigo-600 hover:text-indigo-800 transition duration-150 ease-in-out font-medium underline">
                          click here
                        </button>.
                      </p>
                    </div>
                  </div>

                  <div className="flex justify-end">
                    <ActionButton onClick={handleCancel} color="indigo" styleType="transparent">Cancel</ActionButton>
                    <ActionButton onClick={handleNext} color="indigo">Register</ActionButton>
                  </div>
                </>
              )}
              {step === 2 && (
                <div>
                  <p className="text-sm text-gray-500">
                    The SAML v2 provider configuration has been successfully created and is ready for integration. The following details are crucial for your setup.
                  </p>
                  <div className="mt-5">
                    <div className="mb-4">
                      <p className="font-medium text-gray-600">Name:</p>
                      <p className="text-sm text-gray-600">{providerDetails.name}</p>
                    </div>
                    <div className="mb-4">
                      <p className="font-medium text-gray-600">IdP Endpoint:</p>
                      <p className="text-sm text-gray-600">{providerDetails.idpEndpoint}</p>
                    </div>
                    <div className="mb-4">
                      <p className="font-medium text-gray-600">NameID Format:</p>
                      <p className="text-sm text-gray-600">{providerDetails.nameIdFormat}</p>
                    </div>
                    <div className="mb-4">
                      <p className="font-medium text-gray-600">SAML v2 Callback URL (ACS):</p>
                      <p className="text-sm text-gray-600">{providerDetails.callbackUrl}</p>
                    </div>
                    <div className="mb-4">
                      <p className="font-medium text-gray-600">SAML v2 Issuer:</p>
                      <p className="text-sm text-gray-600">{providerDetails.issuerUrl}</p>
                    </div>
                    <div className="mb-4">
                      <p className="font-medium text-gray-600">SAML v2 Metadata URL:</p>
                      <p className="text-sm text-gray-600">{providerDetails.metadataUrl}</p>
                    </div>

                    <div className="flex justify-end">
                      <ActionButton onClick={handleCancel} color="indigo" >Close</ActionButton>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>

      <ProviderSamlv2CertificatePopup
        isOpen={showCertificatePopup}
        onClose={() => setShowCertificatePopup(false)}
        onUpdate={handleUpdateCertificate}
        currentCertificate={providerDetails.certificateEncoded}
      />
    </section>
  );
};

export default ProviderSamlv2CreateForm;
