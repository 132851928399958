import React, { createContext, useState, useEffect, useContext } from 'react'; 

const AuthenticatedContext = createContext(null);

export const AuthenticatedProvider = ({ children }) => {
    const [profile, setProfile] = useState(() => {
        const savedProfile = localStorage.getItem('userProfile');
        return savedProfile ? JSON.parse(savedProfile) : null;
    });

    useEffect(() => {
        if (profile) {
            localStorage.setItem('userProfile', JSON.stringify(profile));
        } else {
            localStorage.removeItem('userProfile');
        }
    }, [profile]);

    const logout = () => {
        setProfile(null);
    };

    return (
        <AuthenticatedContext.Provider value={{ profile, setProfile, logout }}>
            {children}
        </AuthenticatedContext.Provider>
    );
};

export const useAuthenticatedContext = () => useContext(AuthenticatedContext);
