import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import UserPermissionConfig from '../../pages/users/UserPermissionConfig';
import { ApiClient } from '../../components/common/ApiClient';
import ActionButton from '../../components/ActionButton';

const UserCreateForm = () => {
  const navigate = useNavigate();
  const apiClient = ApiClient();
  const [step, setStep] = useState(1);
  const [user, setUser] = useState({
    name: '',
    email: ''
  });
  const [selectedDefinitions, setSelectedDefinitions] = useState([]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setUser(prev => ({ ...prev, [name]: value }));
  };

  const handleNext = () => {
    if (step === 1) {
      setStep(2);
    } else {
      handleSubmit();
    }
  };

  const handleCancel = () => {
    navigate(`/users`);
  };

  const handleSubmit = async () => {
    const payload = {
      name: user.name,
      email: user.email,
      definitions: selectedDefinitions.map(d => d.id)
    };
    const response = await apiClient.post(`/users`, payload);
    if (response.success) {
      navigate(`/users`);
    }
  };

  return (
    <section className="text-gray-600 body-font">
      <div className="container px-5 py-10 mx-auto flex flex-wrap">
        <div className="flex flex-wrap w-full">
          <div className="lg:w-2/5 md:w-1/2 md:pr-10 md:py-6">
            <div className="flex relative pb-12">
              <div className="h-full w-10 absolute inset-0 flex items-center justify-center">
                <div className="h-full w-1 bg-gray-200 pointer-events-none"></div>
              </div>
              <div className="flex-shrink-0 w-10 h-10 rounded-full bg-indigo-500 inline-flex items-center justify-center text-white relative z-10">
                <svg fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" className="w-5 h-5" viewBox="0 0 24 24">
                  <path d="M12 22s8-4 8-10V5l-8-3-8 3v7c0 6 8 10 8 10z"></path>
                </svg>
              </div>
              <div className="flex-grow pl-4">
                <h2 className="font-medium title-font text-sm text-gray-600 mb-1 tracking-wider">STEP 1: Basic Information</h2>
                <p className="text-sm">Provide the user's name and email address. This information is crucial for creating a unique profile and ensuring effective communication within the system.</p>
              </div>
            </div>
            <div className="flex relative">
              <div className="flex-shrink-0 w-10 h-10 rounded-full bg-indigo-500 inline-flex items-center justify-center text-white relative z-10">
                <svg fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" className="w-5 h-5" viewBox="0 0 24 24">
                  <path d="M22 11.08V12a10 10 0 11-5.93-9.14"></path>
                  <path d="M22 4L12 14.01l-3-3"></path>
                </svg>
              </div>
              <div className="flex-grow pl-4">
                <h2 className="font-medium title-font text-sm text-gray-600 mb-1 tracking-wider">STEP 2: Configure Permissions</h2>
                <p className="text-sm">Define and assign permissions to the user, detailing access rights to various system resources. This step customizes the user's capabilities within the network.</p>
              </div>
            </div>
          </div>
          <div className="lg:w-3/5 md:w-1/2 rounded md:mt-0 mt-12">
            <div className="px-5 py-5 border border-solid rounded">
              <h3 className="text-base font-semibold leading-7 text-gray-600">{step === 1 ? "Create New User" : "Configure User Permissions"}</h3>
              <hr className="my-4 border-gray-200" />
              {step === 1 ? (
                <>
                  <p className="text-sm text-gray-500 mb-4">
                    Please provide the required information below to begin the creation of a new user profile. This initial step is fundamental to establishing a secure and personalized user experience within our system.
                  </p>
                  <form onSubmit={(e) => e.preventDefault()}>
                    <div className="mb-4">
                      <label htmlFor="name" className="block mb-2 text-sm font-medium text-gray-600">Name</label>
                      <input
                        id="name"
                        name="name"
                        type="text"
                        value={user.name}
                        onChange={handleInputChange}
                        className="mt-1 block w-full flex-grow p-2 border rounded mb-2 text-sm text-gray-600 focus:outline-none focus:ring focus:border-indigo-300"
                        required
                      />
                    </div>
                    <div className="mb-4">
                      <label htmlFor="email" className="block mb-2 text-sm font-medium text-gray-600">Email</label>
                      <input
                        id="email"
                        name="email"
                        type="email"
                        value={user.email}
                        onChange={handleInputChange}
                        className="mt-1 block w-full flex-grow p-2 border rounded mb-2 text-sm text-gray-600 focus:outline-none focus:ring focus:border-indigo-300"
                        required
                      />
                    </div>
                    <div className="flex justify-end">
                      <ActionButton onClick={handleCancel} color="indigo" styleType="transparent">
                        Cancel
                      </ActionButton>
                      <ActionButton onClick={handleNext} color="indigo">
                        Next
                      </ActionButton>
                    </div>
                  </form>
                </>
              ) : (
                <div>
                  <UserPermissionConfig
                    mode="create"
                    userDetail={{ email: user.email }}
                    updateSelectedDefinitions={setSelectedDefinitions}
                  />
                  <div className="flex justify-end mt-4">
                    <ActionButton onClick={() => setStep(1)} color="indigo" styleType="transparent">
                      Previous
                    </ActionButton>
                    <ActionButton
                      onClick={handleNext}
                      color="indigo"
                      disabled={selectedDefinitions.length === 0}
                    >
                      Finish
                    </ActionButton>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default UserCreateForm;
