import React from 'react';

const verbs = [
  'get', 'list', 'watch', 'create', 'update', 'delete',
];

const kubernetesResources = [
  'pods', 'deployments', 'replicasets', 'statefulsets', 'daemonsets', 'jobs',
  'cronjobs', 'services', 'ingresses', 'configmaps', 'secrets',
  'persistentvolumeclaims', 'persistentvolumes', 'storageclasses', 'nodes',
  'namespaces', 'serviceaccounts', 'roles', 'rolebindings', 'clusterroles',
  'clusterrolebindings', 'events', 'horizontalpodautoscalers', 'networkpolicies',
  'podsecuritypolicies', 'limitranges', 'resourcequotas', 'customresourcedefinitions',
  'apiresources', 'endpoints', 'podtemplates', 'replicationcontrollers',
];

const ResourceTable = ({
  permissions = {},
  onTogglePermission,
  searchTerm = '',
  setSearchTerm
}) => {
  const filteredResources = kubernetesResources.filter(resource =>
    resource.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const handleTogglePermission = (resource, verb) => {
    const isActive = permissions[resource]?.includes(verb);
    const updatedPermissions = { ...permissions };
    if (isActive) {
      updatedPermissions[resource] = updatedPermissions[resource].filter(v => v !== verb);
    } else {
      if (!updatedPermissions[resource]) updatedPermissions[resource] = [];
      updatedPermissions[resource].push(verb);
    }
    onTogglePermission(updatedPermissions);
  };

  const handleToggleColumn = (verb) => {
    const updatedPermissions = { ...permissions };
    const allSelected = filteredResources.every(resource => permissions[resource]?.includes(verb));

    filteredResources.forEach(resource => {
      if (allSelected) {
        updatedPermissions[resource] = updatedPermissions[resource]?.filter(v => v !== verb) || [];
      } else {
        if (!updatedPermissions[resource]) updatedPermissions[resource] = [];
        if (!updatedPermissions[resource].includes(verb)) {
          updatedPermissions[resource].push(verb);
        }
      }
    });
    onTogglePermission(updatedPermissions);
  };

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  return (
    <div>
      <p className="mb-4 text-sm text-gray-600">Define Kubernetes role permissions to customize the actions allowed for each resource, tailoring role permissions according to your specific requirements.</p>
      <div className="flex items-center justify-between mb-4">
        <input
          type="text"
          placeholder="Search resources..."
          value={searchTerm}
          onChange={handleSearchChange}
          className="mb-4 w-2/5 flex-grow p-2 border rounded mb-2 text-sm text-gray-600 focus:outline-none focus:ring focus:border-indigo-300"
        />
      </div>
      <div className="overflow-x-auto rounded border-gray-500 text-slate-900">
        <table className="min-w-full table-auto">
          <thead>
            <tr>
              <th className="px-3 py-3 font-bold uppercase bg-white border-b text-left">Resource / Verb</th>
              {verbs.map(verb => (
                <th key={verb} className="px-3 py-3 font-bold uppercase text-center border-b ">
                  <div className="flex items-center justify-center">
                    <span>{verb}</span>
                    <input
                      type="checkbox"
                      className="form-checkbox ml-2 accent-indigo-500 appearance-auto size-4"
                      onChange={() => handleToggleColumn(verb)}
                      checked={filteredResources.every(resource => permissions[resource]?.includes(verb))}
                    />
                  </div>
                </th>
              ))}
            </tr>
          </thead>
          <tbody className="bg-white divide-y divide-gray-200">
            {filteredResources.map((resource) => (
              <tr key={resource} className="hover:bg-gray-100">
                <td className="px-3 py-4 whitespace-nowrap text-sm">{resource}</td>
                {verbs.map((verb) => (
                  <td key={`${resource}-${verb}`} className="px-3 py-4 whitespace-nowrap text-center">
                    <label className="inline-flex relative items-center cursor-pointer">
                      <input
                        type="checkbox"
                        checked={permissions[resource]?.includes(verb) ?? false}
                        onChange={() => handleTogglePermission(resource, verb)}
                        className="form-checkbox ml-2 accent-indigo-500 appearance-auto size-4"
                      />
                    </label>
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default ResourceTable;
