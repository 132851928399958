import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ApiClient } from '../../components/common/ApiClient';
import CopyButton from '../../components/CopyButton';
import ActionButton from '../../components/ActionButton';

const AgentCreateForm = () => {
  const [step, setStep] = useState(1);
  const [agent, setAgent] = useState({
    name: '',
    secretKey: ''
  });
  const navigate = useNavigate();
  const { post } = ApiClient();

  const generateSecretKey = () => {
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    let result = '';
    for (let i = 0; i < 32; i++) {
      result += characters.charAt(Math.floor(Math.random() * characters.length));
    }
    return result;
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setAgent(prevAgent => ({ ...prevAgent, [name]: value }));
  };

  const handleNext = async () => {
    if (step === 1) {
      const newSecretKey = generateSecretKey();
      const updatedAgent = { ...agent, secretKey: newSecretKey };
      setAgent(updatedAgent);
      const { success } = await post(`/agents`, updatedAgent);
      if (success) {
        setStep(2);
      }
    } else {
      navigate(`/agents`);
    }
  };

  const handleCancel = () => {
    navigate(`/agents`);
  };

  return (
    <section className="text-gray-600 body-font">
      <div className="container px-5 py-10 mx-auto flex flex-wrap">
        <div className="flex flex-wrap w-full">
          <div className="lg:w-2/5 md:w-1/2 md:pr-10 md:py-6">
            <div className="flex relative pb-12">
              <div className="h-full w-10 absolute inset-0 flex items-center justify-center">
                <div className="h-full w-1 bg-gray-200 pointer-events-none"></div>
              </div>
              <div className="flex-shrink-0 w-10 h-10 rounded-full bg-indigo-500 inline-flex items-center justify-center text-white relative z-10">
                <svg fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" className="w-5 h-5" viewBox="0 0 24 24">
                  <path d="M12 22s8-4 8-10V5l-8-3-8 3v7c0 6 8 10 8 10z"></path>
                </svg>
              </div>
              <div className="flex-grow pl-4">
                <h2 className="font-medium title-font text-sm text-gray-600 mb-1 tracking-wider">STEP 1: Register Agent</h2>
                <p className="text-sm">Input the agent's name to initiate registration. This name helps uniquely identify the agent in your system.</p>
              </div>
            </div>
            <div className="flex relative">
              <div className="flex-shrink-0 w-10 h-10 rounded-full bg-indigo-500 inline-flex items-center justify-center text-white relative z-10">
                <svg fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" className="w-5 h-5" viewBox="0 0 24 24">
                  <path d="M22 11.08V12a10 10 0 11-5.93-9.14"></path>
                  <path d="M22 4L12 14.01l-3-3"></path>
                </svg>
              </div>
              <div className="flex-grow pl-4">
                <h2 className="font-medium title-font text-sm text-gray-600 mb-1 tracking-wider">STEP 2: Agent Installation</h2>
                <p className="text-sm">Use the generated secret key to install the agent using the Helm command provided below. This ensures secure communication between your system and the agent.</p>
              </div>
            </div>
          </div>
          <div className="lg:w-3/5 md:w-1/2 rounded md:mt-0 mt-12">
            <div className="px-5 py-5 border border-solid rounded">
              <h3 className="text-base font-semibold leading-7 text-gray-600">Register New Agent</h3>
              <hr className="my-4 border-gray-200" />
              {step === 1 && (
                <>
                  <p className="text-sm text-gray-500 mb-4">
                    Provide the necessary information to create a new agent in your system. This initial step is critical for establishing the agent's credentials and preparing it for deployment.
                  </p>
                  <form onSubmit={(e) => { e.preventDefault(); handleNext(); }}>
                    <div className="mb-4">
                      <label htmlFor="name" className="block text-sm font-medium text-gray-600">Agent Name</label>
                      <input
                        id="name"
                        name="name"
                        type="text"
                        className="mt-1 block w-full flex-grow p-2 border rounded mb-2 text-sm text-gray-600 focus:outline-none focus:ring focus:border-indigo-300"
                        value={agent.name}
                        onChange={handleInputChange}
                      />
                    </div>
                    <div className="flex justify-end">
                      <ActionButton onClick={handleCancel} color="indigo" styleType="transparent">
                        Cancel
                      </ActionButton>
                      <ActionButton onClick={handleNext} color="indigo">
                        Register
                      </ActionButton>
                    </div>
                  </form>
                </>
              )}
              {step === 2 && (
                <div>
                  <p className="mt-2 max-w-2xl text-sm text-gray-500">
                    Your agent has been registered successfully. Use the following secret key and Helm command to install the agent in your Kubernetes cluster.
                  </p>
                  <div className="mt-5">
                    <div className="flex flex-col space-y-4 mb-4">
                      <div>
                        <p className="font-medium text-gray-600">Agent Name:</p>
                        <div className="relative flex items-center mt-1 bg-gray-50 p-3 rounded">
                          <p className="mt-1 text-sm text-gray-600">{agent.name}</p>
                        </div>
                      </div>
                      <div>
                        <p className="font-medium text-gray-600">Secret Key:</p>
                        <div className="relative flex items-center mt-1 bg-gray-50 p-3 rounded">
                          <code className="text-sm text-gray-600 overflow-auto flex-grow">{agent.secretKey}</code>
                          <CopyButton textToCopy={agent.secretKey} />
                        </div>
                      </div>
                      <div>
                        <p className="font-medium text-gray-600">Helm Command:</p>
                        <div className="relative flex items-start mt-1 bg-gray-50 p-3 rounded">
                          <code className="text-sm text-gray-600 overflow-auto flex-grow">
                            helm install k8sman-agent k8sman/k8sman-helm-chart --set agent.key={agent.secretKey}
                          </code>
                          <CopyButton textToCopy={`helm install k8sman-agent k8sman/k8sman-helm-chart --set agent.key=${agent.secretKey}`} />
                        </div>
                        <p className="mt-4 text-xs text-gray-500">Note: The agent will be installed in the default namespace. You can change the namespace by modifying the Helm command. For more information on IDP mapping, visit <a href="/docs/#idp-mapping" className="text-indigo-600 hover:text-indigo-800">this documentation section</a>.</p>
                      </div>
                    </div>
                    <div className="flex justify-end">
                      <ActionButton onClick={handleNext} color="indigo">
                        Close
                      </ActionButton>
                    </div>
                    
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default AgentCreateForm;
