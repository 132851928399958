import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Sidebar from '../../../components/common/Sidebar';
import ActionButton from '../../../components/ActionButton';
import { ApiClient } from '../../../components/common/ApiClient';
import ModalPaymentMethod from './ModalPaymentMethod';
import { useSessionManager } from '../../../components/auth/AuthenticateManager';
import { faCreditCard, faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import LoadingOverlay from '../../../components/spinner/LoadingOverlay';
import ModalConfirmDelete from './ModalConfirmDelete';

const BillingPage = () => {
  const [invoices, setInvoices] = useState([]);
  const [paymentMethods, setPaymentMethods] = useState([]);
  const [expandedIndex, setExpandedIndex] = useState(null);
  const [subscription, setSubscription] = useState('');
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [customerStripeId, setCustomerStripeId] = useState(null);
  const [showWarning, setShowWarning] = useState(false);
  const [deletePaymentId, setDeletePaymentId] = useState(null);
  const [isLoadingCustomer, setIsLoadingCustomer] = useState(true);
  const [isLoadingPaymentMethods, setIsLoadingPaymentMethods] = useState(true);
  const [isLoadingSubscription, setIsLoadingSubscription] = useState(true);
  const [isLoadingInvoices, setIsLoadingInvoices] = useState(true);
  const [isLoadingAction, setIsLoadingAction] = useState(false);
  const [isDeleteLoadingAction, setIsDeleteLoadingAction] = useState(false);
  const { get, put, del } = ApiClient();
  const { profile } = useSessionManager();
  const navigate = useNavigate(); // Hook do react-router-dom para navegação

  const k8smanBillingUrl = 'http://localhost:8080';

  useEffect(() => {
    if (profile) {
      const customerId = profile.id;
      setIsLoadingCustomer(true);

      get(`/billing/customer/${customerId}`, false, k8smanBillingUrl).then(response => {
        if (response.success && response.data.customerStripeId) {
          setCustomerStripeId(response.data.customerStripeId);

          setIsLoadingSubscription(true);
          get(`/billing/customer/subscription/${response.data.customerStripeId}`, false, k8smanBillingUrl).then(subscriptionResponse => {
            if (subscriptionResponse.success) {
              setSubscription(subscriptionResponse.data.subscription || []);
            }
            setIsLoadingSubscription(false);
          });

          setIsLoadingPaymentMethods(true);
          get(`/billing/customer/payment-method/${response.data.customerStripeId}`, false, k8smanBillingUrl).then(paymentResponse => {
            if (paymentResponse.success) {
              setPaymentMethods(paymentResponse.data.paymentMethods || []);
            }
            setIsLoadingPaymentMethods(false);
          });

          setIsLoadingInvoices(true);
          get(`/billing/customer/invoices/${response.data.customerStripeId}`, false, k8smanBillingUrl).then(invoiceResponse => {
            if (invoiceResponse.success) {
              setInvoices(invoiceResponse.data.invoices || []);
            }
            setIsLoadingInvoices(false);
          });
        } 

        setIsLoadingSubscription(false);
        setIsLoadingPaymentMethods(false);
        setIsLoadingInvoices(false);
        setIsLoadingCustomer(false);

      });
    }
  }, [profile]);

  const handlePaymentMethodPopup = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const handleSetDefault = (paymentId) => {
    setIsLoadingAction(true);
    put(`/billing/customer/payment-method/default/${customerStripeId}/${paymentId}`, {}, false, k8smanBillingUrl).then(response => {
      if (response.success) {
        setPaymentMethods(paymentMethods.map(method => ({
          ...method,
          isDefault: method.paymentId === paymentId
        })));
      }
      setIsLoadingAction(false);
    });
  };

  const handleDelete = (paymentId) => {
    if (paymentMethods.length === 1) {
      setShowWarning(true);
      setDeletePaymentId(paymentId);
      return;
    }
    setIsDeleteLoadingAction(true);
    del(`/billing/customer/payment-method/${customerStripeId}/${paymentId}`, false, k8smanBillingUrl).then(response => {
      if (response.success) {
        setPaymentMethods(paymentMethods.filter(method => method.paymentId !== paymentId));
      }
      setIsDeleteLoadingAction(false);
    });
  };

  const handleConfirmWarning = () => {
    setShowWarning(false);
    setIsDeleteLoadingAction(true);
    if (deletePaymentId) {
      del(`/billing/customer/payment-method/${customerStripeId}/${deletePaymentId}`, false, k8smanBillingUrl).then(response => {
        if (response.success) {
          setPaymentMethods(paymentMethods.filter(method => method.paymentId !== deletePaymentId));
        }
        setIsDeleteLoadingAction(false);
      });
    }
  };

  const onPaymentMethodAdded = () => {
    setIsModalOpen(false);
    setIsLoadingPaymentMethods(true);
    get(`/billing/customer/payment-method/${customerStripeId}`, false, k8smanBillingUrl).then(paymentResponse => {
      if (paymentResponse.success) {
        setPaymentMethods(paymentResponse.data.paymentMethods || []);
      }
      setIsLoadingPaymentMethods(false);
    });
  };

  const toggleAccordion = (index) => {
    setExpandedIndex(expandedIndex === index ? null : index);
  };

  const handleChangeSubscription = () => {
    if (subscription && subscription.productId) {
      navigate(`/settings/billing/subscriptions/${subscription.productId}`);
    }
  };
  

  return (
    <div className="container mx-auto p-3 flex">
      <Sidebar path="Settings" param={{'currentProductId': subscription.productId}} />
      <div className="flex-grow p-4 space-y-8">
        {/* Billing Information Section */}
        <section className="border rounded bg-white p-5">
          <h3 className="text-base font-semibold leading-7 text-gray-600">Billing Information</h3>
          <hr className="my-4 border-gray-200" />
          <LoadingOverlay isLoading={isLoadingSubscription} >
            {!isLoadingSubscription && (
              <>
                <p className="mt-1 text-sm text-gray-600">
                  Your current plan is <strong>{!subscription ? ( <span className="text-sm text-gray-600">No subscription data available</span> ): subscription.productName}</strong>. Manage your subscription details, including switching between different plans such as monthly, annual, or student plans.
                  Check out the various subscription plans available. If you need more detail about plans and compare, check this <a className='text-indigo-500' href='#'>docs</a> page.
                </p>
                <div className="space-y-4 mt-8">
                  <div className="flex justify-between items-center">
                    <div></div>
                    <ActionButton onClick={handleChangeSubscription} color="indigo">
                      Change Subscription
                    </ActionButton>
                  </div>
                </div>
              </>
            )}
           
          </LoadingOverlay>
        </section>

        {/* Payment Information Section */}
        <section className="border rounded bg-white p-5">
          <h3 className="text-base font-semibold leading-7 text-gray-600">Payment Information</h3>
          <hr className="my-4 border-gray-200" />
          <LoadingOverlay isLoading={isLoadingPaymentMethods}>
            <p className="mt-1 text-sm text-gray-600">
              Manage your payment information. For more details, visit this <a className='text-indigo-500' href='#'>docs</a> page.
            </p>
            <div className="space-y-4 mt-8">
              {paymentMethods.length === 0 && !isLoadingPaymentMethods ? (
                <p className="text-sm text-gray-600">No payment methods available. Please add a payment method.</p>
              ) : (
                paymentMethods.map((card, index) => (
                  <div key={index} className="border rounded mb-4">
                    <div
                      className="flex justify-between items-center p-4 cursor-pointer"
                      onClick={() => toggleAccordion(index)}
                    >
                      <div className="flex items-center space-x-4">
                        <div className="text-indigo-500 text-2xl">
                          <FontAwesomeIcon
                            icon={faCreditCard}
                            className="mr-2"
                          />
                        </div>
                        <div>
                          <p className="text-sm text-gray-600">
                            {card.brand.charAt(0).toUpperCase() + card.brand.slice(1)} **** {card.cardNumberLast4}
                          </p>
                        </div>
                        {card.isDefault && (
                          <div className="ml-2 bg-green-100 text-green-600 text-xs px-2 py-1 rounded">
                            Default Payment Card
                          </div>
                        )}
                      </div>
                      <div>
                        <FontAwesomeIcon
                          icon={expandedIndex === index ? faChevronUp : faChevronDown}
                          className={`transform transition-transform duration-300 ${expandedIndex === index ? 'rotate-180' : 'rotate-0'}`}
                        />
                      </div>
                    </div>
                    {expandedIndex === index && (
                      <div className="p-6 border-t overflow-hidden transition-all duration-500 ease-in-out">
                        <div className="grid grid-cols-2 gap-4">
                          <p className="text-sm text-gray-600">
                            <strong>Cardholder Name:</strong> {card.cardholderName || '—'}
                          </p>
                          <p className="text-sm text-gray-600">
                            <strong>Card Number:</strong> **** {card.cardNumberLast4}
                          </p>
                          <p className="text-sm text-gray-600">
                            <strong>Expiration Date:</strong> {card.expMonth} / {card.expYear}
                          </p>
                          <p className="text-sm text-gray-600">
                            <strong>Type:</strong> {card.funding.charAt(0).toUpperCase() + card.funding.slice(1)} {card.brand.charAt(0).toUpperCase() + card.brand.slice(1)}
                          </p>

                          <p className="text-sm text-gray-600">
                            <strong>Origin:</strong> {card.country || '—'}
                          </p>
                          <p className="text-sm text-gray-600">
                            <strong>CVC Check:</strong> {card.cvcCheck || '—'}
                          </p>
                        </div>
                        <div className="flex justify-end space-x-4 mt-4">
                          {!card.isDefault && (
                            <ActionButton onClick={() => handleSetDefault(card.paymentId)} color="indigo" isLoading={isLoadingAction}>
                              Set as Default
                            </ActionButton>
                          )}
                          <ActionButton onClick={() => handleDelete(card.paymentId)} color="red" isLoading={isDeleteLoadingAction}>
                            Delete
                          </ActionButton>
                        </div>
                      </div>
                    )}
                  </div>
                ))
              )}
            </div>
          </LoadingOverlay>
          <div className="flex justify-end mt-6">
            <ActionButton onClick={handlePaymentMethodPopup} color="indigo">
              Add Payment Method
            </ActionButton>
          </div>
          <p className="text-sm text-gray-600 mt-4">
            <img src="https://cdn.worldvectorlogo.com/logos/stripe-4.svg" alt="Stripe Logo" className="w-12 inline-block" /> <br />
            Your payment information is securely stored with <a target="_blank" href='https://stripe.com' className='text-indigo-500'>Stripe</a>. We do not store any payment information on our servers.
          </p>
        </section>

        {/* Warning Modal */}
        <ModalConfirmDelete 
          isOpen={showWarning} 
          onClose={() => setShowWarning(false)} 
          onConfirm={handleConfirmWarning} />
        

        {/* Modal for Payment Method */}
        <ModalPaymentMethod 
          isOpen={isModalOpen} 
          onClose={handleCloseModal} 
          customerStripeId={customerStripeId} 
          profile={profile} onPaymentMethodAdded={onPaymentMethodAdded} />

        {/* Order History Section */}
        <section className="border rounded bg-white p-5">
          <div className="flex justify-between items-center">
            <h3 className="text-gray-800 text-lg font-semibold leading-7">Order History</h3>
            <a href="#" className="text-indigo-600 hover:text-indigo-800 text-sm">View all</a>
          </div>
          <hr className="my-4 border-gray-200" />
          <LoadingOverlay isLoading={isLoadingInvoices} >
            {invoices.length === 0 ? (
              <p className="text-sm text-gray-600">No invoices available for the period.</p>
            ) : (
              <table className="min-w-full divide-y divide-gray-200">
                <thead className="bg-gray-50">
                  <tr>
                    <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Value</th>
                    <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Status</th>
                    <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Frequency</th>
                    <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Invoice Number</th>
                    <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Created</th>
                    <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Download</th>
                  </tr>
                </thead>
                <tbody className="bg-white divide-y divide-gray-200">
                  {invoices.map((invoice, index) => (
                    <tr key={index}>
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{invoice.amount}</td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm">
                        <span className={`px-2 inline-flex text-xs leading-5 font-semibold rounded ${invoice.status === 'paid' ? 'bg-green-100 text-green-800' : 'bg-red-100 text-red-800'}`}>
                          {invoice.status.charAt(0).toUpperCase() + invoice.status.slice(1)}
                        </span>
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{invoice.frequency}</td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">{invoice.number}</td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{invoice.date_created}</td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm">
                        <a href={invoice.download_url} className="text-indigo-600 hover:text-indigo-800" target="_blank" rel="noopener noreferrer">
                          Download
                        </a>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            )}
            
          </LoadingOverlay>
        </section>

        {/* Customer Email Section */}
        <section className="border rounded bg-white p-5">
          <h3 className="text-base font-semibold leading-7 text-gray-600">Customer Email</h3>
          <hr className="my-4 border-gray-200" />
          <LoadingOverlay isLoading={isLoadingCustomer} timeout={5000}>
            {!isLoadingCustomer && (
              <p className="mt-1 text-sm text-gray-600">
                The email associated with your billing is <strong>{profile?.email}</strong>. Ensure this email is accurate for receiving billing-related notifications.
              </p>
            )}
          </LoadingOverlay>
        </section>

        {/* Billing Address Section */}
        <section className="border rounded bg-white p-5">
          <h3 className="text-base font-semibold leading-7 text-gray-600">Billing Address</h3>
          <hr className="my-4 border-gray-200" />
          <LoadingOverlay isLoading={isLoadingCustomer} timeout={5000}>
            {!isLoadingCustomer && (
              <p className="mt-1 text-sm text-gray-600">
                No billing address available. Please add your billing address.
              </p>
            )}
          </LoadingOverlay>
        </section>

        {/* General Information Section */}
        <section className="border rounded bg-white p-5">
          <h3 className="text-gray-800 text-lg font-semibold leading-7">General Information</h3>
          <hr className="my-4 border-gray-200" />
          <LoadingOverlay isLoading={isLoadingCustomer} timeout={5000}>
            {!isLoadingCustomer && (
              <p className="mt-1 text-sm text-gray-600">
                Here you can manage your subscription and view details about your billing history and current plan.
              </p>
            )}
          </LoadingOverlay>
        </section>
      </div>
    </div>
  );
};

export default BillingPage;
