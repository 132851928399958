import React, { useState, useRef, useEffect } from 'react';
import { Link, useNavigate, useLocation, matchPath } from 'react-router-dom';
import { googleLogout } from '@react-oauth/google';
import { useAuthenticatedContext } from '../components/auth/AuthenticateContext';
import RouteDefinitions from '../components/common/RouteDefinitions';


const findRouteByPath = (path, routes) => {
  for (const route of routes) {
    if (matchPath({ path: route.path, end: true }, path)) return route;
    if (route.children) {
      const found = findRouteByPath(path, route.children);
      if (found) return found;
    }
  }
  return null;
};

const Header = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { profile, setProfile } = useAuthenticatedContext();
  const [showDropdown, setShowDropdown] = useState(false);
  const dropdownRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setShowDropdown(false);
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const logOut = () => {
    googleLogout();
    setProfile(null);
    localStorage.removeItem('userProfile');
    navigate('/');
  };

  const toggleDropdown = () => {
    setShowDropdown((prevState) => !prevState);
  };

  const paths = location.pathname.split('/').filter(x => x);
  let breadcrumbs = [];
  let currentPath = '';

  for (const path of paths) {
    currentPath += '/' + path;
    const route = findRouteByPath(currentPath, RouteDefinitions);
    if (route) {
      breadcrumbs.push({ ...route, path: currentPath });  // Salva o caminho atual completo
    }
  }

  const menuItems = RouteDefinitions.map((item) => (
    <Link key={item.label} to={item.path} className={`link ${location.pathname === item.path ? 'active' : ''}`}>
      {item.label}
    </Link>
  ));


  return (
    <div>
      <nav className="bg-white shadow">
        <div className="container mx-auto px-6 py-3 flex justify-between items-center">
          <a href="/dashboard" className="flex title-font font-medium items-center text-gray-600 mb-4 md:mb-0">
            <img src='/K.png' alt='k8sman' className='h-9 antialiased' />
            <span className="ml-3 text-xl">K8SMAN</span>
          </a>
          <div className="flex items-center">
            {menuItems}
            {profile && (
              <div className="relative" ref={dropdownRef}>
                <div className="relative z-10 flex flex-col justify-end h-full px-3 md:w-full">
                  <div className="relative flex items-center justify-end w-full p-1 space-x-4">
                    <span className="h-8 bg-gray-200 border-r border-r-2 border-gray-200"></span>
                    <button onClick={toggleDropdown} className="relative block">
                      <img alt="profile" src={profile.picture} className="mx-auto object-cover rounded-full h-10 w-10" />
                    </button>
                    <button onClick={toggleDropdown} className="flex items-center">
                      {profile.name}
                      <svg width="20" height="20" className="ml-2 text-gray-400" fill="currentColor" viewBox="0 0 1792 1792" xmlns="http://www.w3.org/2000/svg">
                        <path d="M1408 704q0 26-19 45l-448 448q-19 19-45 19t-45-19l-448-448q-19-19-19-45t19-45 45-19h896q26 0 45 19t19 45z"></path>
                      </svg>
                    </button>
                  </div>
                </div>
                {showDropdown && (
                  <div className="absolute right-0 mt-2 py-2 w-48 bg-white rounded shadow-xl z-20">
                    <Link to="/profile" className="block px-4 py-2 hover:bg-gray-100">Profile</Link>
                    <div className="border-t border-gray-100"></div>
                    <button onClick={logOut} className="block w-full text-left px-4 py-2 hover:bg-gray-100">Sign Out</button>
                  </div>
                )}
              </div>
            )}
          </div>
        </div>
      </nav>

      <nav aria-label="Breadcrumb" className="container mx-auto px-6 py-5 flex">
        <ol className="list-reset flex">
          <li className="inline-flex items-center">
            <Link to="/" className="inline-flex items-center text-indigo-600 hover:text-indigo-800">
              <svg className="w-3 h-3 me-2.5 mr-2" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                <path d="m19.707 9.293-2-2-7-7a1 1 0 0 0-1.414 0l-7 7-2 2a1 1 0 0 0 1.414 1.414L2 10.414V18a2 2 0 0 0 2 2h3a1 1 0 0 0 1-1v-4a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v4a1 1 0 0 0 1 1h3a2 2 0 0 0 2-2v-7.586l.293.293a1 1 0 0 0 1.414-1.414Z" />
              </svg>
              Home
            </Link>
          </li>
          <span className=" ml-2 text-indigo-600 mr-2">/</span>
          {breadcrumbs.map((breadcrumb, index) => (
            <React.Fragment key={breadcrumb.path}>
              <Link
                to={breadcrumb.path}
                className={`text-indigo-600 hover:text-indigo-800 ${index < breadcrumbs.length - 1 ? 'mr-2' : ''}`}
              >
                {breadcrumb.label}
              </Link>
              {index < breadcrumbs.length - 1 && (<span className="text-indigo-600 mr-2">/</span>)}
            </React.Fragment>
          ))}
        </ol>
      </nav>
      <hr />
    </div>
  );
};

export default Header;
