import React from 'react';
import { loadStripe } from '@stripe/stripe-js';
import ActionButton from '../../../components/ActionButton';

const stripePromise = loadStripe('pk_test_51OjkceI0rUjks4W1S75A8h7bQEGpNfhcEMNj8rg1D890PHWWzWBl8CJmNPxTk4dPc9PGuhMYohLbWUPluTjFjQfd00EetkxZtw');

const ModalConfirmDelete = ({ isOpen, onClose, onConfirm }) => {
    if (!isOpen) return null;

    return (
        <div className="fixed inset-0 z-10 overflow-y-auto">
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" aria-hidden="true"></div>
            <div className="flex items-end justify-center min-h-full p-4 text-center sm:items-center sm:p-0">
              <div className="relative bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:max-w-lg sm:w-full">
                <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                  <div className="sm:flex sm:items-start">
                    <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                      <h3 className="text-lg leading-6 font-medium text-gray-900" id="modal-title">
                        Confirm Deletion
                      </h3>
                      <div className="mt-2">
                        <p className="text-sm text-gray-500">
                          Deleting this payment method will make the platform functionalities unavailable. Please keep at least one payment method.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">

                <ActionButton onClick={onConfirm} color="red">
                    Confirm
                </ActionButton>
                  <ActionButton onClick={onClose} type="button" color="red" styleType="transparent">
                    Cancel
                </ActionButton>
                </div>
              </div>
            </div>
          </div>
    );
};

export default ModalConfirmDelete;
